import { supabase } from "supabaseClient";
import { useMutation } from "@tanstack/react-query";
import { Database } from "supaBaseTypes";

export interface updateUserByIdProps {
  clientData: Partial<Database["public"]["Tables"]["profile"]["Row"]>;
}

// this needs better error handling
export const createAuthUser = async ({ clientData }: updateUserByIdProps) => {
  if (!clientData.email) {
    throw new Error("missing user email");
  }
  const { data: authData, error: authError } =
    await supabase.auth.api.inviteUserByEmail(clientData.email);
  if (authError) {
    throw new Error(authError.message);
  }
  if (!authData) {
    throw new Error("missing user authentication id");
  }

  return authData;
};

const useCreateAuthUser = () => {
  const key = ["createAuthUser"];
  return useMutation(key, createAuthUser);
};

export default useCreateAuthUser;
