import React from "react";
import { Container } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppHeader from "client/components/header/AppHeader";
// this needs better styling
import Page404 from "./client/components/404/404";
import ProtectedRoute from "./client/components/ProtectedRoute/ProtectedRoute";
import Client from "./client/pages/client/Client";
import InvestmentPage from "client/pages/InvestmentPage/InvestmentPage";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ClientCurrency from "client/pages/ClientCurrencyPage/ClientCurrencyPage";
import CreateClient from "./client/pages/createclient/CreateClient";
import CreateInvestment from "./client/pages/createinvestment/createInvestment";
// get rid of this and do it in a modal in the edit client
import DeactivateUser from "./client/pages/deactivateuser/DeactivateUser";
import EditClient from "./client/pages/editclient/EditClient";
import EditClientDetails from "./client/pages/editclientdetails/EditClientDetails";
import EditInvestment from "./client/pages/editinvestment/EditInvestment";
import TradesList from "client/pages/ListTrades/ListTrades";
// this might not be relevant for now
import Investmentclientpool from "./client/pages/investmentclientpool/investmentclientpool";
// this can be a modal in the edit client
// it only updates the users otp and password reset email
import PasswordRecovery from "./client/pages/passwordrecovery/PasswordRecovery";
import Settings from "./client/pages/settings/settings";
import Signin from "./client/pages/signin/Signin";
import TradeBlotter from "client/pages/TradeBlotter/TradeBlotter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppHeader />
          <Container
            sx={{
              backgroundColor: "#0e1e2e",
              height: "100vh",
              overflow: "scroll",
              paddingTop: "64px",
            }}
            disableGutters
            maxWidth={false}
          >
            <Routes>
              <Route index element={<Signin />} />
              <Route
                path={"signin"}
                element={
                  <ProtectedRoute>
                    <Signin />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"settings"}
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"investments"}
                element={
                  <ProtectedRoute>
                    <InvestmentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"createinvestment"}
                element={
                  <ProtectedRoute>
                    <CreateInvestment />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"investmentclientpool/:type"}
                element={
                  <ProtectedRoute>
                    <Investmentclientpool />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"clients"}
                element={
                  <ProtectedRoute>
                    <Client />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"createclient"}
                element={
                  <ProtectedRoute>
                    <CreateClient />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"client/:type"}
                element={
                  <ProtectedRoute>
                    <EditClient />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"editclient/:type"}
                element={
                  <ProtectedRoute>
                    <EditClientDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"passwordrecovery/:type"}
                element={
                  <ProtectedRoute>
                    <PasswordRecovery />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"deactivateuser/:userId"}
                element={
                  <ProtectedRoute>
                    <DeactivateUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"editinvestment/:type"}
                element={
                  <ProtectedRoute>
                    <EditInvestment />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"clientcurrency/:type/:type1"}
                element={
                  <ProtectedRoute>
                    <ClientCurrency />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"tradeblotter"}
                element={
                  <ProtectedRoute>
                    <TradeBlotter />
                  </ProtectedRoute>
                }
              />
              <Route
                path={"trade-history"}
                element={
                  <ProtectedRoute>
                    <TradesList />
                  </ProtectedRoute>
                }
              />
              <Route path={"*"} element={<Page404 />} />
            </Routes>
          </Container>
        </BrowserRouter>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
