import React from "react";
import { Form, Input, Button, message } from "antd";
import "./Signin.css";
import { supabase } from "../../../supabaseClient";
import { useNavigate, Navigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/image 2.svg";
import useLogOut from "client/hooks/supabase/useLogout";

const Signin = () => {
  const handleLogOutMutation = useLogOut();
  const navigate = useNavigate();
  const user = supabase.auth.user();

  const onSubmit = async (values: any) => {
    const { user, error } = await supabase.auth.signIn({
      email: values.email,
      password: values.password,
    });

    // use the meta values for clients
    if (error) {
      message.error("Invalid login credentials");
    } else {
      supabase
        .from("profile")
        .select("*")
        .eq("user_id", user?.id)
        .then(({ data }) => {
          // this has some weird state issue
          if (data?.[0].role === "admin") {
            navigate("/clients");
          } else {
            // this errors out with dupes sometimes
            message.error("You don't have access");
            logout();
          }
        });
    }
  };

  function logout() {
    handleLogOutMutation.mutate(undefined, {
      onSuccess: () => navigate("/signin"),
    });
  }

  if (user) {
    return <Navigate to="/clients" replace />;
  }

  return (
    <div className="signin-div">
      <div>
        <div style={{ textAlign: "center" }}>
          <Logo
            style={{
              width: "220px",
              height: "75px",
            }}
          />
        </div>

        <div className="title-login" style={{ marginTop: "80px" }}>
          Admin Login
        </div>
        <Form
          style={{ width: "470px", marginTop: "80px" }}
          layout="vertical"
          name="login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
        >
          <Form.Item
            style={{ color: "white" }}
            name="email"
            rules={[
              {
                required: true,
                message: "Please type your email!",
              },
            ]}
            hasFeedback
          >
            <Input
              bordered={false}
              className="input-login"
              type="email"
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
            ]}
          >
            <Input.Password
              bordered={false}
              className="input-login"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            style={{
              paddingTop: "60px",
              margin: "0px",
              maxWidth: "469px",
              width: "100%",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ height: "60px", width: "260px" }}
                htmlType="submit"
                className="primary-button"
              >
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Signin;
