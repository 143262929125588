import React, { useEffect } from "react";
import { Form } from "formik";
import {
  Grid,
  Select,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Autocomplete,
  Alert,
  Button,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { FormikProps } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TradeBlotterFormTypes } from "./BlotterFormUtils";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CreateIcon from "@mui/icons-material/Create";

// only do buy side and swap the quotes
const tradeTypeOptions = [
  {
    value: "BUY",
    label: "Buy",
  },
  // {
  //   value: "SELL",
  //   label: "Sell",
  // },
];
interface TradeBlotterFormProps {
  formikProps: FormikProps<TradeBlotterFormTypes>;
  settlementCounterparties: any;
  refetchFormData: () => void;
  tradingCounterparties: any;
  referralsOptions: any;
  entitiesOptions: any;
  allUsersOptions: any;
  allAssetsOptions: any;
  counterpartiesLoading: boolean;
  allUsersLoading: boolean;
  entitiesLoading: boolean;
  allAssetsLoading: boolean;
  referralsLoading: boolean;
  successfulSubmit: string;
  submitError: string;
  handleFormReset: () => void;
}
// you'll see NaN type errors since the defaults are set to undefined instead of ''
// fix the types later with the clean up of the anys

// fix the fiat to btc prices

const TradeBlotterForm = ({
  formikProps,
  refetchFormData,
  settlementCounterparties,
  tradingCounterparties,
  referralsOptions,
  entitiesOptions,
  allUsersOptions,
  allAssetsOptions,
  counterpartiesLoading,
  allUsersLoading,
  entitiesLoading,
  allAssetsLoading,
  referralsLoading,
  successfulSubmit,
  submitError,
  handleFormReset,
}: TradeBlotterFormProps) => {
  const userEntity = entitiesOptions?.find(
    (option: any) => option.uuid === formikProps.values.selectedUser?.entity_id
  );

  useEffect(() => {
    formikProps.setFieldValue("entity_uuid", userEntity?.uuid);
  }, [userEntity]);

  const quoteAssetAmount = formikProps.values?.baseAssetDetails?.is_fiat
    ? Number(formikProps.values?.base_asset_amount) /
      Number(formikProps.values?.quote_external)
    : Number(formikProps.values?.quote_external) *
      Number(formikProps.values?.base_asset_amount);

  useEffect(() => {
    formikProps.setFieldValue("quote_asset_amount", quoteAssetAmount);
  }, [quoteAssetAmount]);

  const CADAmount =
    // if cad no rate application is needed
    formikProps.values?.quoteAssetDetails?.ticker === "CAD"
      ? quoteAssetAmount
      : // else apply fx
      quoteAssetAmount &&
        formikProps.values?.cad_rate &&
        !formikProps.values?.baseAssetDetails?.is_fiat
      ? quoteAssetAmount * formikProps.values.cad_rate
      : quoteAssetAmount &&
        formikProps.values?.cad_rate &&
        formikProps.values?.baseAssetDetails?.is_fiat
      ? Number(formikProps.values?.base_asset_amount) *
        formikProps.values.cad_rate
      : 0;

  useEffect(() => {
    formikProps.setFieldValue("cad_equivalent_amount", CADAmount);
  }, [CADAmount]);
  const quoteSpread = formikProps.values?.baseAssetDetails?.is_fiat
    ? Number(formikProps.values?.quote_external) -
      Number(formikProps.values?.quote_internal)
    : Number(formikProps.values?.quote_internal) -
      Number(formikProps.values?.quote_external);
  const tradeProformaPNL = formikProps.values?.baseAssetDetails?.is_fiat
    ? quoteSpread * Number(formikProps.values?.quote_asset_amount)
    : quoteSpread * Number(formikProps.values?.base_asset_amount);

  // enter to tab on fields needs to use refs
  // this will take a while to implement
  return (
    <Form>
      <Grid container sx={{ backgroundColor: "white" }}>
        <Grid
          item
          xs={12}
          container
          sx={{ m: 2, alignItems: "center", justifyContent: "flex-end" }}
        >
          <Grid item xs={6}>
            {successfulSubmit ? (
              <Alert variant="outlined" severity="success">
                {successfulSubmit}
              </Alert>
            ) : submitError ? (
              <Alert variant="outlined" severity="error">
                {submitError}
              </Alert>
            ) : null}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip title="New Submission">
              <IconButton
                onClick={() => (formikProps.resetForm(), handleFormReset())}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refetch Field Options">
              <IconButton onClick={refetchFormData}>
                <SyncAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset">
              <IconButton
                onClick={() => (formikProps.resetForm(), handleFormReset())}
              >
                <BackspaceIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* Left Column */}
        <Grid item xs={12} sm={6} container>
          <Grid item xs={12} container alignItems={"center"} m={1}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                loading={allUsersLoading}
                loadingText={"Loading Users"}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props} key={option.value}>
                      {option.name}
                    </Box>
                  );
                }}
                style={{ width: "100%" }}
                disabled={allUsersLoading}
                onChange={(_, value) => {
                  formikProps.setFieldValue("selectedUser", value);
                  formikProps.setFieldTouched("selectedUser");
                }}
                getOptionLabel={(option) => option.label}
                value={formikProps.values.selectedUser || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client Name"
                    name="selectedUser"
                    error={Boolean(formikProps.errors.selectedUser)}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.user_uuid === value.user_uuid;
                }}
                options={allUsersOptions || []}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel id="entity-select">Entity</InputLabel>
                <Select
                  defaultValue={userEntity?.uuid || null}
                  variant="outlined"
                  labelId="entity-select"
                  label="Entity"
                  name="entity_uuid"
                  disabled={entitiesLoading}
                  onChange={formikProps.handleChange}
                  style={{ width: "100%" }}
                  value={
                    formikProps.values.entity_uuid || userEntity?.uuid || ""
                  }
                  error={Boolean(formikProps.errors.entity_uuid)}
                >
                  {entitiesOptions?.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} alignItems={"center"} container m={1}>
            <DatePicker
              label="Trade Date"
              closeOnSelect={true}
              value={
                (formikProps.values.trade_datetime &&
                  new Date(formikProps.values.trade_datetime)) ||
                Date.now()
              }
              orientation="portrait"
              onChange={(value) => {
                formikProps.setFieldValue("trade_datetime", value);
                formikProps.setFieldTouched("trade_datetime");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="trade_datetime"
                  fullWidth
                  error={Boolean(formikProps.errors.trade_datetime)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} alignItems={"center"} container m={1}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                disabled={counterpartiesLoading}
                onChange={(_, value) => {
                  formikProps.setFieldValue("counterpartyDetails", value);
                  formikProps.setFieldTouched("counterpartyDetails");
                }}
                getOptionLabel={(option) => option.label}
                value={formikProps.values.counterpartyDetails || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Counterparty"
                    name="counterpartyDetails"
                    error={Boolean(formikProps.errors.counterpartyDetails)}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                options={tradingCounterparties || []}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                disabled={counterpartiesLoading}
                onChange={(_, value) => {
                  formikProps.setFieldValue("settlementDetails", value);
                  formikProps.setFieldTouched("settlementDetails");
                }}
                getOptionLabel={(option) => option.label}
                value={formikProps.values.settlementDetails || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Settlement"
                    name="settlementDetails"
                    error={Boolean(formikProps.errors.settlementDetails)}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                options={settlementCounterparties || []}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} alignItems={"center"} container m={1}>
            <Autocomplete
              loading={referralsLoading}
              loadingText={"Loading Referrals"}
              style={{ width: "100%" }}
              onChange={(_, value) => {
                formikProps.setFieldValue("referralDetails", value);
                formikProps.setFieldTouched("referralDetails");
              }}
              getOptionLabel={(option) => option.label}
              value={formikProps.values.referralDetails || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Referral"
                  name="referralDetails"
                  error={Boolean(formikProps.errors.referralDetails)}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              options={referralsOptions || []}
            />
          </Grid>
          <Grid item xs={12} alignItems={"center"} container m={1}>
            {/* this actually needs to be array later */}
            <TextField
              label="Inbound TXID"
              onChange={formikProps.handleChange}
              style={{ width: "100%" }}
              name="inbound_client_transaction"
              placeholder="Inbound TXID"
              error={Boolean(formikProps.errors.inbound_client_transaction)}
              value={formikProps.values.inbound_client_transaction || ""}
            />
          </Grid>
        </Grid>

        {/* Right Column */}

        <Grid item xs={12} sm={6} container>
          <Grid item xs={12} container m={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                label="Internal Price (Ryki)"
                inputProps={{ step: 0.00001 }}
                style={{ width: "100%" }}
                name="quote_internal"
                onChange={formikProps.handleChange}
                placeholder="internal price"
                value={formikProps.values.quote_internal || ""}
                error={Boolean(formikProps.errors.quote_internal)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                label="External Price (Client)"
                inputProps={{ step: 0.00001 }}
                style={{ width: "100%" }}
                name="quote_external"
                placeholder="qoute external"
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.quote_external)}
                value={formikProps.values.quote_external || ""}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container m={1}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                disabled={allAssetsLoading}
                onChange={(_, value) => {
                  formikProps.setFieldValue("baseAssetDetails", value);
                  formikProps.setFieldTouched("baseAssetDetails");
                }}
                getOptionLabel={(option) => option.ticker}
                value={formikProps.values.baseAssetDetails || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Base Asset"
                    name="baseAssetDetails"
                    error={Boolean(formikProps.errors.baseAssetDetails)}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                options={allAssetsOptions || []}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                inputProps={{ step: 0.00001 }}
                label="Base Amount"
                name="base_asset_amount"
                placeholder="base amount"
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.base_asset_amount)}
                value={formikProps.values.base_asset_amount || ""}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container m={1}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                disabled={allAssetsLoading}
                onChange={(_, value) => {
                  formikProps.setFieldValue("quoteAssetDetails", value);
                  formikProps.setFieldTouched("quoteAssetDetails");
                }}
                getOptionLabel={(option) => option.ticker}
                value={formikProps.values.quoteAssetDetails || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Quote Asset"
                    name="quoteAssetDetails"
                    error={Boolean(formikProps.errors.quoteAssetDetails)}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                options={allAssetsOptions || []}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                label="Qoute Amount"
                type="number"
                inputProps={{ step: 0.00001 }}
                style={{ width: "100%" }}
                value={quoteAssetAmount || ""}
                name="quote_asset_amount"
                placeholder="qoute amount"
                error={Boolean(formikProps.errors.quote_asset_amount)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container m={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={formikProps.handleChange}
                label="CAD Rate"
                type="number"
                inputProps={{ step: 0.00001 }}
                style={{ width: "100%" }}
                name="cad_rate"
                placeholder="cad rate"
                error={Boolean(formikProps.errors.cad_rate)}
                value={formikProps.values.cad_rate || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                label="CAD Notional"
                type="number"
                value={CADAmount || ""}
                style={{ width: "100%" }}
                name="cad_equivalent_amount"
                placeholder="CAD notional"
                error={Boolean(formikProps.errors.cad_equivalent_amount)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container m={1}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel id="trade-side-select">
                  Trade Type (Ryki)
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="trade-side-select"
                  label="Trade Type (Ryki)"
                  name="trade_type"
                  onChange={formikProps.handleChange}
                  style={{ width: "100%" }}
                  value={formikProps.values.trade_type || "BUY"}
                  error={Boolean(formikProps.errors.trade_type)}
                >
                  {tradeTypeOptions?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  height: "100%",
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                }}
              >
                <Typography>
                  {`Ryki ${formikProps.values.trade_type}S `}
                  {formikProps.values?.baseAssetDetails?.ticker} for{" "}
                  {formikProps.values?.quoteAssetDetails?.ticker}
                </Typography>
                <Typography>
                  {`Expected profit: $`}
                  {tradeProformaPNL ? tradeProformaPNL.toFixed(2) : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* this should float */}
        <Grid item xs={12} container sx={{ alignItems: "center" }}>
          <Grid item xs={6}>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              type="submit"
              disabled={
                formikProps.isSubmitting ||
                !!successfulSubmit ||
                !formikProps.dirty
              }
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default TradeBlotterForm;
