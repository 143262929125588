import * as Yup from "yup";

export const CreateClientSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  fireblocksId: Yup.object().required("Required").nullable(),
  phoneNumber: Yup.string().required("Required"),
  fiatAmount: Yup.number(),
  ethAmount: Yup.number(),
  //   ideally we shouldnt set the entity uuid as an empty string but im not gonna spend the time to deal with the select
  entity_uuid: Yup.string()
    .test(
      "entity-id-check",
      "Select an entity",
      (entity) => !entity || entity.length >= 10
    )
    .required("Required"),
});

export const mapUserProfileToCreateClientForm = (userProfile: any) => {
  return {
    name: userProfile.name,
    fireblocksId: userProfile.fireBlockID,
    entity_uuid: userProfile.entity_id,
    phoneNumber: userProfile.phone_number,
    fiatAmount: userProfile.fiat_amount,
    email: userProfile.email,
    ethAmount: userProfile.eth_amount,
  };
};
