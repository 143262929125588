import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export const getAllUsers = async () => {
  const { data, error } = await supabase
    .from("profile")
    .select("*, entity:entities(*)");
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSelectAllUsers = () => {
  const key = ["getAllUsers"];
  return useQuery(key, () => getAllUsers());
};

export default useSelectAllUsers;
