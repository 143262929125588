import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const getVaultAccounts = async () => {
  // this can just call the query directly.
  // doesnt need express since we have a backend
  const { data } = await axios.post("/api/fireblock");
  return data;
};

const useGetAllVaultAccounts = () => {
  const key = ["getAllVaultAccounts"];
  return useQuery(key, () => getVaultAccounts());
};

export default useGetAllVaultAccounts;
