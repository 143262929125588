import { Space, message } from "antd";
import "../../../App.css";
import { supabase } from "../../../supabaseClient";
import "./PasswordRecovery.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ReactComponent as BackImage } from "../../../assets/back-button.svg";

function PasswordRecovery() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<any>([]);
  const { type } = useParams();

  useEffect(() => {
    supabase
      .from("profile")
      .select("*")
      .eq("user_id", type)
      .then(({ data, error }) => {
        if (!error) setProfile(data[0]);
      });
  }, [type]);

  function sendotp() {
    axios
      .post("/api/deleteclient", {
        userid: profile.user_id,
      })
      .then((res) => {
        if (res.status === 200) {
          axios
            .post("/api/createclient", {
              email: profile.email,
            })
            .then((res) => {
              supabase
                .from("profile")
                .update([
                  {
                    email: res.data.email,
                    user_id: res.data.id,
                  },
                ])
                .eq("user_id", type)
                // this should error check properly
                .then(({ error }) => {
                  if (!error) {
                    message.success("Otp sent successfully");
                    navigate("/clients");
                  }
                });
              if (res.data.message) {
                message.info(res.data.message);
              }
            });
        }
      });
  }

  const onForget = async () => {
    const { error } = await supabase.auth.api.resetPasswordForEmail(
      profile.email,
      { redirectTo: `${process.env.REACT_APP_DOMAIN}/resetpassword` }
    );

    if (!error) {
      message.success("Email sent succesfully");
    }
  };

  return (
    <div
      style={{
        // padding: "200px 0px 48px 0px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Space
        direction="vertical"
        size="large"
        style={{
          width: "100%",
          padding: "0px 20px 0px 20px ",
          maxWidth: "1262px",
        }}
      >
        <Space className="back-navidation">
          <BackImage
            style={{ width: "18px", height: "24px", cursor: "pointer" }}
            onClick={() => navigate(`/client/${type}`)}
            alt=""
          />
        </Space>

        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
            maxWidth: "450px",
            margin: "0px 180px 0px 180px",
          }}
        >
          <div className="main-heading">Password Recovery</div>
          <div
            className="normal-text"
            style={{
              marginTop: "40px ",
              padding: "0px 25px 0px 25px",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#17314c",
              borderRadius: "10px",
              height: "50px",
            }}
          >
            <span>First time set-up OTP</span>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => sendotp()}
            >
              Send New OTP
            </span>
          </div>
          <div
            className="normal-text"
            style={{
              padding: "0px 25px 0px 25px",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#17314c",
              borderRadius: "10px",
              height: "50px",
            }}
          >
            <span>Password Recovery link</span>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => onForget()}
            >
              Send Link
            </span>
          </div>
        </Space>
      </Space>
    </div>
  );
}

export default PasswordRecovery;
