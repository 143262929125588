import * as Yup from "yup";
import { Json } from "supaBaseTypes";

export const defaultTradeBlotterFormValues: TradeBlotterFormTypes = {
  base_asset_amount: undefined,
  base_asset_uuid: undefined,
  cad_equivalent_amount: undefined,
  cad_rate: undefined,
  counterparty_uuid: undefined,
  created_at: undefined,
  entity_uuid: undefined,
  fintrac_report: undefined,
  has_fintrac: undefined,
  inbound_client_transaction: "",
  inbound_counterparty_transaction: undefined,
  outbound_client_transaction: undefined,
  outbound_trade_counterparty: undefined,
  quote_asset_amount: null,
  quote_asset_uuid: undefined,
  quote_external: undefined,
  quote_internal: undefined,
  referral_uuid: undefined,
  settlement_counterparty_uuid: undefined,
  settlement_date: undefined,
  trade_datetime: Date.now(),
  trade_type: "BUY",
  trade_uuid: undefined,
  user_uuid: undefined,
};

export interface TradeBlotterFormTypes {
  referralDetails?: any;
  settlementDetails?: any;
  counterpartyDetails?: any;
  quoteAssetDetails?: any;
  baseAssetDetails?: any;
  selectedUser?: any;
  base_asset_amount?: number | null;
  base_asset_uuid?: string | undefined;
  cad_equivalent_amount?: number;
  cad_rate?: number;
  counterparty_uuid?: string | null;
  created_at?: string | null;
  entity_uuid?: string;
  fintrac_report?: Json | null;
  has_fintrac?: boolean;
  inbound_client_transaction?: string | null;
  inbound_counterparty_transaction?: string | null;
  outbound_client_transaction?: string | null;
  outbound_trade_counterparty?: string | null;
  quote_asset_amount?: number | null;
  quote_asset_uuid?: string;
  quote_external?: number;
  quote_internal?: number;
  referral_uuid?: string | null;
  settlement_counterparty_uuid?: string;
  settlement_date?: string;
  trade_datetime?: undefined | number | Date;
  trade_type?: string;
  trade_uuid?: string;
  user_uuid?: string;
}

export const BlotterFormSchema = Yup.object().shape({
  trade_datetime: Yup.string().required("Required"),
  cad_equivalent_amount: Yup.number().required("Required"),
  cad_rate: Yup.number().required("Required"),
  entity_uuid: Yup.string()
    .test(
      "entity-id-check",
      "Select an entity",
      (entity) => !entity || entity.length >= 10
    )
    .required("Required"),
  selectedUser: Yup.object().required("Required"),
  inbound_client_transaction: Yup.string().required("Required"),
  baseAssetDetails: Yup.object().required("Required"),
  quoteAssetDetails: Yup.object().required("Required"),
  quote_asset_amount: Yup.number().required("Required"),
  quote_external: Yup.number().required("Required"),
  quote_internal: Yup.number().required("Required"),
  trade_type: Yup.string().required("Required"),
  counterpartyDetails: Yup.object().required("Required"),
  settlementDetails: Yup.object().required("Required"),
  referralDetails: Yup.object().nullable(),
  base_asset_amount: Yup.number().required("Requried"),
});
