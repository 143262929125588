import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export const getAllEntities = async () => {
  const { data, error } = await supabase.from("entities").select("*");
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSelectAllEntities = () => {
  const key = ["getAllEntities"];
  return useQuery(key, () => getAllEntities());
};

export default useSelectAllEntities;
