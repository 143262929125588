import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { ReactComponent as BackImage } from "../../../assets/back-button.svg";
import { supabase } from "../../../supabaseClient";
import "./EditInvestment.css";

const { Option } = Select;

function EditInvestment() {
  const [name, setName] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [structure, setStructure] = useState<string>("");
  const [rate, setRate] = useState<string>("");
  const [effectivedate, setEffectivedate] = useState<Moment | string | null>();
  const [expirydate, setExpirydate] = useState<Moment | string | null>();
  const [returndate, setReturndate] = useState<Moment | string | null>();
  const navigate = useNavigate();
  // this is a investment uuid
  const { type } = useParams();

  useEffect(() => {
    supabase
      .from("investmentDetails")
      .select("*")
      .eq("investment_uuid", type || null)
      .then(({ data }) => {
        setName(data?.[0].name || "");
        setCurrency(data?.[0].currency || "");
        setQuantity(data?.[0].quantity || "");
        setStructure(data?.[0].structure || "");
        setRate(data?.[0].rate || "");
        setEffectivedate(data?.[0].effectiveDate);
        setExpirydate(data?.[0].expiryDate);
        setReturndate(data?.[0].returnDate);
      });
  }, [type]);

  //Dropdown
  const currencyHandler = (value: any) => {
    setCurrency(value);
  };
  const structureHandler = (value: any) => {
    setStructure(value);
  };
  //Dropdown

  //create investment
  const onFinish = async () => {
    const { error } = await supabase
      .from("investmentDetails")
      .update({
        name: name,
        currency: currency,
        quantity: quantity,
        structure: structure,
        rate: rate,
        effectiveDate: moment(effectivedate).format("YYYY-MM-DD"),
        expiryDate: moment(expirydate).format("YYYY-MM-DD"),
        returnDate: moment(returndate).format("YYYY-MM-DD"),
      })
      .eq("investment_uuid", type);
    if (!error) {
      message.success("Saved successfully");
      navigate("/investmentclientpool/" + type);
    }
  };
  //create investment
  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} is required!",
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      email: "${label} is not a valid email!",
      // eslint-disable-next-line no-template-curly-in-string
      number: "${label} is not a valid number!",
    },
    number: {
      // eslint-disable-next-line no-template-curly-in-string
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        sx={{
          margin: 2,
        }}
      >
        <Space className="back-navidation">
          <BackImage
            style={{ width: "18px", height: "24px", cursor: "pointer" }}
            onClick={() => navigate("/investments")}
            alt=" "
          />
        </Space>
        <div
          style={{
            background: "rgba(0, 0, 0, 0.36)",
            borderRadius: "10px",
            padding: "40px 80px 40px 80px",
          }}
        >
          <div className="main-heading">Investment details</div>
          <div>
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              labelWrap={true}
              validateMessages={validateMessages}
            >
              <Space
                direction="vertical"
                style={{ width: "100%", padding: "40px 10px 40px 10px" }}
              >
                <Row>
                  <Col span={12} style={{ paddingRight: "60px" }}>
                    <Form.Item
                      className="form-text"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        bordered={false}
                        className="form-input"
                        placeholder="Enter name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ paddingLeft: "60px" }}>
                    <Form.Item
                      className="form-text"
                      label="Select currency"
                      rules={[
                        {
                          required: true,
                          message: "Please select currency!",
                        },
                      ]}
                    >
                      <Select
                        style={{ height: "50px" }}
                        className="primary-select"
                        bordered={false}
                        value={currency}
                        placeholder="Select team size"
                        onChange={currencyHandler}
                      >
                        <Option value="ETH">ETH</Option>
                        <Option value="USDT_ERC20">USDT_ERC20</Option>
                        <Option value="BTC">BTC</Option>
                        <Option value="USDC">USDC</Option>
                        <Option value="USD">USD</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ paddingRight: "60px" }}>
                    <Form.Item
                      className="form-text"
                      label="Quantity"
                      rules={[
                        {
                          required: true,
                          message: "Please enter quantity!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        bordered={false}
                        className="form-input"
                        placeholder="Enter quantity"
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ paddingLeft: "60px" }}>
                    <Form.Item
                      className="form-text"
                      label="Select structure"
                      rules={[
                        {
                          required: true,
                          message: "Please select currency!",
                        },
                      ]}
                    >
                      <Select
                        className="primary-select"
                        bordered={false}
                        value={structure}
                        placeholder="Select team size"
                        onChange={structureHandler}
                      >
                        <Option value="FT">FT</Option>
                        <Option value="OT">OT</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ paddingRight: "60px" }}>
                    <Form.Item
                      className="form-text"
                      label="Rate"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        bordered={false}
                        className="form-input"
                        placeholder="Enter rate"
                        type="number"
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ paddingLeft: "60px" }}>
                    <Form.Item
                      className="form-text"
                      label="Effective Date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter effective date!",
                        },
                      ]}
                      hasFeedback
                    >
                      <DatePicker
                        value={moment(effectivedate)}
                        bordered={false}
                        style={{ width: "100%" }}
                        className="form-input-datepicker"
                        placeholder="Enter effective date"
                        onChange={(e) => setEffectivedate(e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ paddingRight: "60px" }}>
                    <Form.Item
                      className="form-text"
                      label="Expiry date"
                      rules={[
                        {
                          required: structure === "FT" ? true : false,
                          message: "Please enter expiry date!",
                        },
                      ]}
                      hasFeedback
                    >
                      <DatePicker
                        value={moment(expirydate)}
                        bordered={false}
                        style={{ width: "100%" }}
                        className="form-input-datepicker"
                        placeholder="Enter expiry date"
                        onChange={(e) => {
                          setExpirydate(e);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ paddingLeft: "60px" }}>
                    <Form.Item
                      className="form-text"
                      style={{ color: "#ffffff" }}
                      label="Return Date"
                      rules={[
                        {
                          required: structure === "FT" ? true : false,
                          message: "Please enter return date!",
                        },
                      ]}
                      hasFeedback
                    >
                      <DatePicker
                        value={moment(returndate)}
                        bordered={false}
                        style={{ width: "100%" }}
                        className="form-input-datepicker"
                        placeholder="Enter return date"
                        onChange={(e) => {
                          setReturndate(e);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Space>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Form.Item>
                  <Button
                    className="primary-button"
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "164px",
                      height: "40px",
                      borderRadius: "50px",
                      border: "none",
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default EditInvestment;
