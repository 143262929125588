import React, { useEffect, useState } from "react";
import LoadingSpinner from "client/components/LoadingSpinner/LoadingSpinner";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  EditClientTableColumns,
  normalizedClientBalances,
  CustomToolbar,
} from "./EditClientTableUtils";

import { DataGridPremium } from "@mui/x-data-grid-premium";

import { supabase } from "../../../supabaseClient";
import { Grid, Typography, CircularProgress, Button } from "@mui/material";
import EditClientForm from "./EditClientForm";
import useSelectUserByParam from "client/hooks/supabase/useSelectUserProfileByParam";
import useSelectClientInvestments from "client/hooks/supabase/useSelectClientInvestments";
import useUpdateUserByIdMutation from "client/hooks/supabase/useUpdateUserProfile";
import useGetUserVault from "client/hooks/RykiApi/useGetUserVault";

// this needs some error handling for each call
function EditClient() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isViewOnly, setViewOnly] = useState(true);
  const { type: userId } = useParams();
  const [client, setClient] = useState<any>([]);

  const noAuthUser = state.idType === "profile_uuid";
  const queryParam = noAuthUser ? "profile_uuid" : "user_id";

  const {
    data: userProfile,
    isLoading: userProfileLoading,
    isError: userProfileError,
  } = useSelectUserByParam(queryParam, userId || "");

  const {
    data: userVaultData,
    isLoading: userVaultIsLoading,
    refetch: refetchUserVaultData,
  } = useGetUserVault(
    userProfile?.[0]?.fireBlockID,
    userProfile?.[0]?.entity_id
  );
  useEffect(() => {
    setClient(userProfile?.[0]);
  }, [userProfile]);

  const {
    data: userInvestments,
    isLoading: userInvestmentsLoading,
    isError: userInvestmentsError,
    refetch: refetchClientInvestments,
  } = useSelectClientInvestments(noAuthUser ? undefined : userId);

  // double check these types
  const dataAsset = normalizedClientBalances(
    userProfile,
    userInvestments,
    userVaultData?.["assets"]
  );
  const isProfileDataLoading =
    userProfileLoading || userProfileError || userVaultIsLoading;

  const isAuthDataLoading = userInvestmentsLoading || userInvestmentsError;

  const isDataLoading = noAuthUser
    ? isProfileDataLoading
    : isProfileDataLoading && isAuthDataLoading;
  // user proper  error handling and loaidn gspinner

  const handleRefetch = () => {
    refetchClientInvestments();
    refetchUserVaultData();
  };

  function ActivateUser() {
    supabase
      .from("profile")
      .update([
        {
          status: "Active",
        },
      ])
      // this will break if theres a userprofile but no user auth
      // since we're allowing some users that trade but don't hold balances
      .eq("user_id", userId)
      .then(({ error }) => {
        if (!error) {
          console.log("success");
          // navigate("/clients");
        }
      });
  }
  const updateUserByIdMutation = useUpdateUserByIdMutation();
  const handlePasswordAttemptsReset = () => {
    // this will break if theres a userprofile but no user auth
    // since we're allowing some users that trade but don't hold balances
    if (userId) {
      updateUserByIdMutation.mutate(
        { clientData: { block_count: 0 }, value: userId, param: "user_id" },
        {
          onSuccess: () => console.log("success"),
          onError: (resp) => console.log(resp),
        }
      );
    }
  };

  // function DeactivateUser() {
  //   axios
  //     .post("/api/checkpassword", {
  //       email: user?.email,
  //       password: password,
  //     })
  //     .then((res) => {
  //       if (res.data.id) {
  //         supabase
  //           .from("profile")
  //           .update([
  //             {
  //               status: "Inactive",
  //               block_count: "-1",
  //             },
  //           ])
  //           .eq("user_id", userId)
  //           .then(({ error }) => {
  //             if (!error) {
  //               message.success("Deactivated successfully");
  //               navigate(`/client/${userId}`);
  //             }
  //           });
  //         if (res.data.message) {
  //           message.info(res.data.message);
  //         }
  //       } else {
  //         message.error("Invalid credentials");
  //       }
  //     });
  // }

  if (isDataLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Grid container>
      <Grid item xs={12} container sx={{ m: 2, alignItems: "center" }}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Edit Client
          </Typography>
        </Grid>
        <Grid xs={12} item container>
          {client && userVaultData ? (
            <EditClientForm
              isViewOnly={isViewOnly}
              setViewOnly={setViewOnly}
              userProfile={client}
              userVaultData={userVaultData}
              refetchUserVaultData={refetchUserVaultData}
            />
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} container sx={{ m: 2 }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ color: "white", marginBottom: 2 }}>
            Client Actions
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Typography color="white" variant="h6">
                Recover User Password
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={noAuthUser}
                fullWidth
                variant="contained"
                // this will break if theres a userprofile but no user auth
                // since we're allowing some users that trade but don't hold balances
                onClick={() => navigate(`/passwordrecovery/${userId}`)}
              >
                Password Recovery
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Typography color="white" variant="h6">
                Deactivate User
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {/* this doesnt need to be a page you can do it here */}
              {/* api call to profille to set inactive */}
              {/* model to check since they have active balances */}
              <Button
                fullWidth
                variant="contained"
                onClick={() => navigate(`/deactivateuser/${userId}`)}
              >
                Deactivate User
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Typography color="white" variant="h6">
                Activate User
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => ActivateUser()}
              >
                Activate User
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={8}>
              <Typography color="white" variant="h6">
                Reset Password Block
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={noAuthUser}
                fullWidth
                variant="contained"
                onClick={handlePasswordAttemptsReset}
              >
                Reset Block
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container sx={{ m: 2, alignItems: "center" }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Client Asset Information
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container sx={{ m: 2, alignItems: "center" }}>
        {dataAsset ? (
          <DataGridPremium
            componentsProps={{
              toolbar: { loading: isDataLoading, refetch: handleRefetch },
            }}
            //
            // onRowClick={handleOnRowClick}
            components={{
              Toolbar: CustomToolbar,
            }}
            // this should be a uniq id. name can be duped
            getRowId={(row) => {
              return row.internalId;
            }}
            sx={{ backgroundColor: "white", height: 600 }}
            rows={dataAsset}
            columns={EditClientTableColumns}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Grid>
    </Grid>
  );
}

export default EditClient;
