import React, { useState } from "react";
import { Formik } from "formik";
import { Grid, Typography, Box } from "@mui/material";
import TradeBlotterForm from "./components/BlotterForm/BlotterForm";
import { defaultTradeBlotterFormValues } from "./components/BlotterForm/BlotterFormUtils";
import useUpdateTradeMutation from "client/hooks/supabase/useUpdateOTCTrade";
import {
  TradeBlotterFormTypes,
  BlotterFormSchema,
} from "./components/BlotterForm/BlotterFormUtils";
import { v4 as uuidv4 } from "uuid";
import useSelectAllEntities from "client/hooks/supabase/useSelectAllEntities";
import useSelectAllUsers from "client/hooks/supabase/useSelectAllUsers";
import useSelectAllReferrals from "client/hooks/supabase/useSelectAllReferrals";
import useSelectAllCounterparties from "client/hooks/supabase/useSelectAllCounterparties";
import useSelectAllAssets from "client/hooks/supabase/useSelectAllAssets";
import useSaveTradeConfirmPDF from "client/hooks/supabase/useSaveTradeConfirmPDF";
import useEmailTradeConfirmPDF from "client/hooks/supabase/useEmailTradeConfirmPDF";
import { supabase } from "supabaseClient";

const TradeBlotter = () => {
  const user = supabase.auth.user();
  const [submissionError, setSubmissionError] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState("");
  // this should take an optional trade id later to update the trade
  const UpdateTradeMutation = useUpdateTradeMutation();
  const SaveTradeConfirmPdfMutation = useSaveTradeConfirmPDF();
  const EmailTradeConfirmMutation = useEmailTradeConfirmPDF();
  const {
    data: entitiesData,
    isLoading: entitiesLoading,
    refetch: refetchAllEntities,
  } = useSelectAllEntities();

  // this needs to do a preflight check on the uuidv4 for the trade id, before submission

  //useCheckUUID
  // this shoudl really do a prefetch to create the row / delete if they cancel
  // if error generate and check again
  // if no error
  // tradeUuid = usecheckuuid.result

  // this needs validation next

  //   later add the ability to edit a trade
  const handleSubmit = (values: TradeBlotterFormTypes, actions: any) => {
    actions.setSubmitting(false);

    const hasFintrac =
      entitiesData?.find((entity) => entity.uuid === values.entity_uuid)
        ?.country_shortcode === "CA" &&
      !values.baseAssetDetails?.is_fiat &&
      values?.cad_equivalent_amount &&
      values?.cad_equivalent_amount > 10000;
    const submitFormat = {
      ...values,
      settlement_date: values.trade_datetime
        ? new Date(values.trade_datetime).toISOString()
        : new Date().toISOString(),
      has_fintrac: !!hasFintrac,
      trade_uuid: uuidv4(),
      trade_datetime: values.trade_datetime
        ? new Date(values.trade_datetime).toISOString()
        : new Date().toISOString(),
      user_uuid: values.selectedUser.user_id,
      profile_uuid: values.selectedUser.profile_uuid,
      base_asset_uuid: values.baseAssetDetails.value,
      quote_asset_uuid: values.quoteAssetDetails.value,
      counterparty_uuid: values.counterpartyDetails.value,
      settlement_counterparty_uuid: values.settlementDetails.value,
      referral_uuid: values?.referralDetails?.value,
      created_by: user?.id,
    };

    // this is because auto complete doesnt like using single prop values well
    // easier to implement and adjust the end value than to mess with a custom autocomplete.
    // we also don't manually remap cause there's a lot of props on the submission object.

    delete submitFormat["selectedUser"];
    delete submitFormat["baseAssetDetails"];
    delete submitFormat["quoteAssetDetails"];
    delete submitFormat["counterpartyDetails"];
    delete submitFormat["settlementDetails"];
    delete submitFormat["settlementDetails"];
    delete submitFormat["referralDetails"];

    // add a validation step for entitiy chosen does not equal the user's entity
    // show popup for confirmation

    // this needs a better step loading ui
    UpdateTradeMutation.mutate(
      { tradeData: submitFormat },
      {
        // then create tradeconfirm pdf and save with the edge function
        onSuccess: (resp) => (
          // this should be the uuid that the next one uses
          console.log(resp),
          setSubmissionSuccess("successfully created trade"),
          SaveTradeConfirmPdfMutation.mutate(
            {
              // this us unsafe and should double check the results
              tradeUuid: submitFormat.trade_uuid,
            },
            {
              onSuccess: (resp) => (
                console.log("trade-confirm", resp),
                setSubmissionSuccess("successfully generated pdf"),
                EmailTradeConfirmMutation.mutate(
                  {
                    // this us unsafe and should double check the results
                    tradeUuid: submitFormat.trade_uuid,
                  },
                  {
                    onSuccess: (resp) => (
                      console.log(resp),
                      setSubmissionSuccess("successfully emailed trade confirm")
                    ),
                    onError: (err: any) => (
                      setSubmissionError(err.message), setSubmissionSuccess("")
                    ),
                  }
                )
              ),
              onError: (err: any) => (
                setSubmissionError(err.message), setSubmissionSuccess("")
              ),
            }
          )
        ),
        onError: (resp: any) => (
          setSubmissionError(resp.message), setSubmissionSuccess("")
        ),
      }
    );
  };

  const {
    data: referrals,
    refetch: refetchReferralsData,
    isLoading: referralsLoading,
  } = useSelectAllReferrals();
  const {
    data: allAssets,
    isLoading: allAssetsLoading,
    refetch: refetchAllAssets,
  } = useSelectAllAssets();

  const {
    data: allUsers,
    isLoading: allUsersLoading,
    refetch: refetchAllUsers,
  } = useSelectAllUsers();
  const {
    data: allCounterparties,
    isLoading: counterpartiesLoading,
    refetch: refetchAllCounterparties,
  } = useSelectAllCounterparties();
  const allAssetsOptions = allAssets?.map((asset: any) => {
    return {
      ...asset,
      label: asset.name,
      value: asset.uuid,
    };
  });
  const allUsersOptions = allUsers?.map((party: any) => {
    return {
      ...party,
      label: party.name,
      value: party.user_id || party.profile_uuid,
    };
  });
  const entitiesOptions = entitiesData?.map((party: any) => {
    return {
      ...party,
      label: `${party.name} (${party.country_shortcode})`,
      value: party.uuid,
    };
  });
  const referralsOptions = referrals?.map((party: any) => {
    return { ...party, label: party.name, value: party.uuid };
  });
  const tradingCounterparties = allCounterparties
    ?.filter((xparty: any) => xparty.type === "market_maker")
    .map((party: any) => {
      return { ...party, label: party.display_name, value: party.uuid };
    });
  const settlementCounterparties = allCounterparties
    ?.filter((xparty: any) => xparty.type !== "market_maker")
    .map((party: any) => {
      return { ...party, label: party.display_name, value: party.uuid };
    });

  const refetchFormData = () => {
    refetchReferralsData();
    refetchAllAssets();
    refetchAllCounterparties();
    refetchAllUsers();
    refetchAllEntities();
  };

  const handleFormReset = () => {
    setSubmissionError("");
    setSubmissionSuccess("");
  };

  return (
    <Grid container>
      <Grid item xs={12} container sx={{ m: 2, alignItems: "center" }}>
        <Grid item xs={6}>
          <Typography variant="body1" sx={{ color: "white" }}>
            Trade Blotter
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box m={2}>
          <Formik
            onSubmit={handleSubmit}
            initialValues={defaultTradeBlotterFormValues}
            validationSchema={BlotterFormSchema}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            enableReinitialize
          >
            {(formik) => (
              <TradeBlotterForm
                refetchFormData={refetchFormData}
                formikProps={formik}
                settlementCounterparties={settlementCounterparties}
                tradingCounterparties={tradingCounterparties}
                referralsOptions={referralsOptions}
                entitiesOptions={entitiesOptions}
                allUsersOptions={allUsersOptions}
                allAssetsOptions={allAssetsOptions}
                counterpartiesLoading={counterpartiesLoading}
                allUsersLoading={allUsersLoading}
                entitiesLoading={entitiesLoading}
                allAssetsLoading={allAssetsLoading}
                referralsLoading={referralsLoading}
                successfulSubmit={submissionSuccess}
                submitError={submissionError}
                handleFormReset={handleFormReset}
              />
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TradeBlotter;
