import * as Yup from "yup";

export const EditClientSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  email: Yup.string().nullable(),
  fireblocksId: Yup.object().nullable(),
  phoneNumber: Yup.string().nullable(),
  fiatAmount: Yup.number().nullable(),
  ethAmount: Yup.number().nullable(),
  entity_uuid: Yup.string().nullable(),
});
