import { GridColDef } from "@mui/x-data-grid";
export const InvestmentTableColumns: GridColDef[] = [
  {
    headerName: "name",
    field: "name",
    width: 120,
  },
  {
    headerName: "Currency",
    field: "currency",
    width: 120,
  },
  {
    headerName: "Quantity",
    field: "quantity",
    width: 120,
  },

  {
    headerName: "Structure",
    field: "structure",
    width: 120,
  },
  {
    headerName: "Rate",
    field: "rate",
    width: 120,
  },
  {
    headerName: "Effective date",
    field: "effectiveDate",
    width: 120,
  },
  {
    headerName: "Expiry date",
    field: "expiryDate",
    width: 120,
  },
  {
    headerName: "Return date",
    field: "returnDate",
    width: 120,
  },
];
