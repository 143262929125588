import { supabase } from "supabaseClient";
import { useMutation } from "@tanstack/react-query";
import { Database } from "supaBaseTypes";

export interface updateUserByIdProps {
  clientData: Partial<Database["public"]["Tables"]["profile"]["Row"]>;
  value: string;
  param: string;
}

export const updateUserByParam = async ({
  clientData,
  value,
  param = "user_id",
}: updateUserByIdProps) => {
  const { data, error } = await supabase
    .from("profile")
    .update(clientData)
    .eq(param, value)
    .select();
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useUpdateUserByParamMutation = () => {
  const key = ["updateProfile"];
  return useMutation(key, updateUserByParam, {});
};

export default useUpdateUserByParamMutation;
