import { supabase } from "supabaseClient";
import { useMutation } from "@tanstack/react-query";
import { Database } from "supaBaseTypes";

export interface updateTradeProps {
  tradeData: Partial<
    Database["public"]["Tables"]["otc_client_trades"]["Insert"]
  >;
}

export const updateTrade = async ({ tradeData }: updateTradeProps) => {
  const { data, error } = await supabase
    .from("otc_client_trades")
    .upsert([tradeData]);
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useUpdateTradeMutation = () => {
  const key = ["updateTrade"];
  return useMutation(key, updateTrade, {});
};

export default useUpdateTradeMutation;
