import { Form, Input, Button, Space, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "../../../App.css";
import { supabase } from "../../../supabaseClient";
import "./DeactivateUser.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ReactComponent as BackImage } from "../../../assets/back-button.svg";

function DeactivateUser() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState<any>("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const user = supabase.auth.user();

  useEffect(() => {
    supabase
      .from("profile")
      .select("*")
      .eq("user_id", userId)
      .then(({ data }) => {
        setClient(data?.[0]);
      });
  }, [userId]);

  function DeactivateUser() {
    axios
      .post("/api/checkpassword", {
        email: user?.email,
        password: password,
      })
      .then((res) => {
        if (res.data.id) {
          supabase
            .from("profile")
            .update([
              {
                status: "Inactive",
                block_count: "-1",
              },
            ])
            .eq("user_id", userId)
            .then(({ error }) => {
              if (!error) {
                message.success("Deactivated successfully");
                navigate(`/client/${userId}`);
              }
            });
          if (res.data.message) {
            message.info(res.data.message);
          }
        } else {
          message.error("Invalid credentials");
        }
      });
  }

  return (
    <div
      style={{
        // padding: "200px 0px 48px 0px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Space
        direction="vertical"
        size="large"
        style={{
          width: "100%",
          padding: "0px 20px 0px 20px",
          maxWidth: "1262px",
        }}
      >
        <Space className="back-navidation">
          <BackImage
            style={{ width: "18px", height: "24px", cursor: "pointer" }}
            onClick={() => navigate(`/client/${userId}`)}
            alt=""
          />
        </Space>
        <Space
          direction="vertical"
          style={{
            width: "100%",
            paddingLeft: "180px",
          }}
        >
          <Space
            direction="vertical"
            size="middle"
            style={{
              maxWidth: "830px",
              width: "100%",
            }}
          >
            <div className="main-heading">Deactivate User</div>
            <Space
              className="info"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <InfoCircleOutlined />
              This user has active investment. Are you sure you want to
              deactivate this user?
            </Space>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                padding: "50px 120px 50px 120px",
                backgroundColor: "#17314c",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  padding: "10px 0px 10px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Space direction="vertical" style={{ width: "50%" }}>
                  <div className="normal-heading">Name </div>
                  <div className="normal-text">{client?.name}</div>
                </Space>
                <Space direction="vertical" style={{ width: "50%" }}>
                  <div className="normal-heading">Phone Number</div>
                  <div className="normal-text">{client?.phone_number} </div>
                </Space>
              </div>
              <div
                style={{
                  padding: "10px 0px 10px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Space direction="vertical" style={{ width: "50%" }}>
                  <div className="normal-heading">Email </div>
                  <div className="normal-text">{client?.email}</div>
                </Space>
                <Space direction="vertical" style={{ width: "50%" }}>
                  <div className="normal-heading">Fireblocks ID</div>
                  <div className="normal-text">{client?.fireBlockID} </div>
                </Space>
              </div>
            </Space>
            <Space size="large">
              <Button
                className="primary-button"
                type="primary"
                style={{
                  width: "164px",
                  height: "50px",
                  borderRadius: "50px",
                  border: "none",
                }}
                onClick={() => setVisible(true)}
              >
                Deactivate User
              </Button>
              <Button
                className="white-button"
                type="primary"
                style={{
                  width: "164px",
                  height: "50px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/client/" + userId)}
              >
                Cancel
              </Button>
            </Space>
            {visible ? (
              <>
                <Space
                  className="info"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  Enter Admin Password and Click confirm to deactivate user
                </Space>
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{ maxWidth: "470px" }}
                    bordered={false}
                    className="input-login"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Button
                  className="primary-button"
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "164px",
                    height: "50px",
                    borderRadius: "50px",
                    border: "none",
                  }}
                  onClick={() => DeactivateUser()}
                >
                  Confirm
                </Button>
              </>
            ) : null}
          </Space>
        </Space>
      </Space>
    </div>
  );
}

export default DeactivateUser;
