import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export interface getUserByIdProps {
  userId?: string;
  queryString?: string | "*";
}

export const getClientInvestments = async ({
  userId,
  queryString,
}: getUserByIdProps) => {
  const { data, error } = await supabase
    .from("clientpool")
    .select(queryString)
    .eq("user_id", userId);
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSelectClientInvestments = (userId?: string, queryString?: string) => {
  const key = ["clientInvestments", userId];
  return useQuery(key, () => getClientInvestments({ userId, queryString }), {
    enabled: !!userId,
  });
};

export default useSelectClientInvestments;
