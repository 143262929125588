import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export const getAllCounterparties = async () => {
  const { data, error } = await supabase.from("counterparties").select("*");
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSelectAllCounterparties = () => {
  const key = ["getAllCounterparties"];
  return useQuery(key, () => getAllCounterparties());
};

export default useSelectAllCounterparties;
