import { Database } from "supaBaseTypes";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
export const ClientTableColumns: GridColDef[] = [
  {
    headerName: "Name",
    field: "name",
    width: 120,
  },
  {
    headerName: "Fireblocks ID",
    field: "fireBlockID",
    width: 120,
  },
  {
    headerName: "Email",
    field: "email",
    width: 120,
  },
  {
    headerName: "Phone Number",
    field: "phone_number",
    width: 120,
  },
  {
    headerName: "Status",
    field: "status",
    width: 120,
  },
  {
    headerName: "Entity",
    field: "entity",
    valueGetter: (params: GridValueGetterParams) => {
      return params?.value?.name || "";
    },
    width: 80,
  },
  {
    headerName: "User Id",
    field: "user_id",
    width: 80,
  },
  {
    headerName: "Role",
    field: "role",
    width: 60,
  },
];

export const filterClients = (
  entry: Database["public"]["Tables"]["profile"]["Row"],
  currValue: string
) => {
  if (entry?.name || entry?.fireBlockID) {
    return (
      entry?.name?.toLowerCase().includes(currValue) ||
      entry?.fireBlockID?.toLowerCase().includes(currValue)
    );
  }
  return false;
};
