import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";
// import { DatabaseTypes } from "supaBaseTypes";

export const getAllReferrals = async () => {
  const { data: referrals, error } = await supabase
    .from("referrals")
    .select("*");
  if (error) {
    throw new Error(error.message);
  }
  return referrals;
};

const useSelectAllReferrals = () => {
  const key = ["getAllReferrals"];
  return useQuery(key, getAllReferrals);
};
export default useSelectAllReferrals;
