import React from "react";
import { Button } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid";

export const EditClientTableColumns: GridColDef[] = [
  {
    headerName: "Asset Name",
    field: "name",
    width: 200,
  },
  {
    headerName: "Total Quantity",
    field: "total",
  },
  {
    headerName: "Venue",
    field: "venue",
  },
];

// this should be cleaner backend return
export const normalizedClientBalances = (
  userProfile: any,
  userInvestments: any,
  vaultBalances: any
) => {
  let formattedBalances = [] as any;
  if (userProfile) {
    formattedBalances = [
      ...formattedBalances,
      {
        name: "ETH",
        total: userProfile?.eth_amount || 0,
        venue: "Fireblocks",
      },
      {
        name: "FIAT",
        total: userProfile?.fiat_amount || 0,
        venue: "Bank",
      },
    ];
  }
  if (vaultBalances) {
    const formattedVaultBalances = vaultBalances.map((vault: any) => {
      return { name: vault.id, total: vault.total, venue: "Fireblocks" };
    });
    formattedBalances = [...formattedBalances, ...formattedVaultBalances];
  }
  if (userInvestments) {
    const formattedUserInvestments = userInvestments.map((investment: any) => {
      return {
        name: investment.currency,
        // this needs to account for interest but we dont have investments atm
        total: investment.quantity,
        // this needs a proper venue
        venue: "Ryki Yield",
      };
    });
    formattedBalances = [...formattedBalances, ...formattedUserInvestments];
  }
  formattedBalances = formattedBalances.map((row: any, index: any) => {
    return {
      ...row,
      internalId: index,
    };
  });
  return formattedBalances;
};

export function CustomToolbar({ refetch, loading }: any) {
  return (
    <GridToolbarContainer>
      <Button disabled={loading} onClick={refetch}>
        Reload
      </Button>
    </GridToolbarContainer>
  );
}
