import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export interface getUserProfileByParamProps {
  param: string;
  value: string;
}
const queryString =
  "currency, email, eth_amount, fiat_amount,fireBlockID,name,id, phone_number, entity_id,user_id, profile_uuid";
export const getUserProfileByParam = async ({
  value,
  param = "user_id",
}: getUserProfileByParamProps) => {
  const { data, error } = await supabase
    .from("profile")
    .select(queryString)
    .eq(param, value);

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSelectUserProfileByParam = (param: string, value: string) => {
  const key = ["userProfile", value, param];
  return useQuery(key, () => getUserProfileByParam({ param, value }));
};

export default useSelectUserProfileByParam;
