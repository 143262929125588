import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  OutlinedInput,
  Button,
  Autocomplete,
  createFilterOptions,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { CreateClientSchema } from "./CreateClientUtils";
import { useNavigate } from "react-router-dom";
import useSelectAllEntities from "client/hooks/supabase/useSelectAllEntities";
import useCreateUserProfile from "client/hooks/supabase/useCreateUserProfile";
import useGetAllVaultAccounts from "client/hooks/RykiApi/useGetAllVaultUsers";
import useCreateAuthUser from "client/hooks/supabase/useCreateAuthUser";
import useCreateAdminUser from "client/hooks/supabase/useCreateAdminUser";
import { getUserProfileByParam } from "client/hooks/supabase/useSelectUserProfileByParam";

function CreateClient() {
  const [initialUserProfile, setInitialUserProfile] = useState<any>({
    name: "",
    email: "",
    fireblocksId: "",
    phoneNumber: "",
    fiatAmount: "",
    ethAmount: "",
    entity_uuid: "",
  });
  const [emailValid, setEmailValid] = useState(false);
  const [emailTooltip, setEmailTooltip] = useState(false);
  const [shouldCreateAuthUser, setShouldCreateAuthUser] = useState(true);

  const {
    data: entitiesData,
    isLoading: entitiesLoading,
    refetch: refetchEntitiesData,
  } = useSelectAllEntities();
  const entitiesOptions = entitiesData?.map((party: any) => {
    return {
      ...party,
      label: `${party.name} (${party.country_shortcode})`,
      value: party.uuid,
    };
  });

  // this needs an error reload
  const {
    data: vaultData,
    isLoading: vaultIsLoading,
    refetch: refetchVaultData,
  } = useGetAllVaultAccounts();

  // filter vaults by entity selected
  const fireblocksOptions = vaultData?.map((party: any) => {
    return {
      ...party,
      label: party.name,
      value: party.id,
    };
  });
  // @ts-ignore
  const filterOptions = createFilterOptions<any>({
    stringify: (option) => `${option.value}${option.name}`,
  });

  const navigate = useNavigate();

  const createUserProfileMutation = useCreateUserProfile();
  const createAuthUser = useCreateAuthUser();
  const createAdminUser = useCreateAdminUser();

  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(false);
    const formattedData = {
      clientData: {
        name: values.name,
        email: values.email,
        role: values.isAdmin ? "admin" : "client",
        fireBlockID: values.fireblocksId.id,
        phone_number: values.phoneNumber,
        fiat_amount: values.fiatAmount,
        eth_amount: values.ethAmount,
        entity_id: values.entity_uuid,
      },
    };

    // if admin create auth user without otp and email confirm
    if (values.isAdmin) {
      createAdminUser.mutate(
        { ...formattedData, password: "admin123" },
        {
          onError: (err) => console.log(err),
          onSuccess: (resp) => {
            createUserProfileMutation.mutate(
              { ...formattedData, userId: resp.id },
              {
                onSuccess: () => (
                  console.log("Admin added successfully"), navigate("/clients")
                ),
              }
            );
          },
        }
      );
      // create a client for the app with otp confi
    } else if (shouldCreateAuthUser && !values.isAdmin) {
      createAuthUser.mutate(formattedData, {
        onError: (err) => console.log(err),
        onSuccess: (resp) => {
          createUserProfileMutation.mutate(
            { ...formattedData, userId: resp.id },
            {
              onSuccess: () => (
                console.log("Client added successfully"), navigate("/clients")
              ),
            }
          );
        },
      });
      // create a client without auth for app
    } else {
      createUserProfileMutation.mutate(
        { ...formattedData, userId: undefined },
        {
          onSuccess: () => (
            console.log("Client added successfully"), navigate("/clients")
          ),
        }
      );
    }
  };

  const refetchFormData = () => {
    refetchVaultData();
    refetchEntitiesData();
  };

  const handleCheckEmail = (email: string) => {
    getUserProfileByParam({ value: email, param: "email" })
      .then((resp) => {
        // there should only be 1 email in the profile table
        if (resp.length > 0) {
          const user = resp[0];
          // if there's a profile with a user id then don't create an auth user
          if (user.user_id) {
            setShouldCreateAuthUser(false);
            setEmailValid(true);
            // this should error and say a user exists
          } else {
            setShouldCreateAuthUser(true);
            setEmailValid(false);
          }
          setInitialUserProfile(user);
        } else if (resp.length == 0) {
          setShouldCreateAuthUser(true);
          setEmailValid(true);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const userVault =
    fireblocksOptions?.filter(
      (vault: any) =>
        Number(vault.id) === Number(initialUserProfile?.fireBlockID)
    )?.[0] || undefined;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        sx={{
          margin: 2,
        }}
      >
        <Grid item xs={12} container sx={{ m: 2, alignItems: "center" }}>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ color: "white" }}>
              Create &amp; Edit New Client profile
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Formik
            enableReinitialize={true}
            validateOnBlur={false}
            validationSchema={CreateClientSchema}
            onSubmit={handleSubmit}
            initialValues={{
              name: initialUserProfile?.name || "",
              email: initialUserProfile?.email || "",
              fireblocksId: userVault || "",
              phoneNumber: initialUserProfile?.phone_number || "",
              fiatAmount: initialUserProfile?.fiat_amount || "",
              ethAmount: initialUserProfile?.eth_amount || "",
              entity_uuid: initialUserProfile?.entity_id || "",
              isAdmin: false,
            }}
            validateOnChange={false}
          >
            {(formik) => {
              return (
                <Form>
                  <Grid container sx={{ backgroundColor: "white" }}>
                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12} container alignItems={"center"}>
                        <FormControl
                          style={{ width: "100%", margin: 8 }}
                          variant="outlined"
                        >
                          <InputLabel
                            shrink={true}
                            htmlFor="outlined-adornment-email"
                          >
                            Email
                          </InputLabel>
                          <OutlinedInput
                            notched
                            id="outlined-adornment-email"
                            label="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder="email"
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            type="text"
                            endAdornment={
                              <InputAdornment position="end">
                                <Tooltip
                                  onClose={() => setEmailTooltip(false)}
                                  onOpen={() => setEmailTooltip(true)}
                                  open={emailTooltip && !emailValid}
                                  title="Verify Email"
                                >
                                  <IconButton
                                    onClick={() =>
                                      handleCheckEmail(
                                        formik.values.email || ""
                                      )
                                    }
                                    onMouseDown={() =>
                                      handleCheckEmail(
                                        formik.values.email || ""
                                      )
                                    }
                                    edge="end"
                                  >
                                    {emailValid ? (
                                      <CheckBoxIcon />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            }
                          />
                        </FormControl>

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.name}
                          disabled={!emailValid}
                          label="Name"
                          style={{ width: "100%", margin: 8 }}
                          name="name"
                          onChange={formik.handleChange}
                          placeholder="name"
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                        />

                        <TextField
                          disabled={!emailValid}
                          value={formik.values?.phoneNumber}
                          type="tel"
                          label="Phone Number"
                          style={{ width: "100%", margin: 8 }}
                          name="phoneNumber"
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          placeholder="Phone Number"
                          error={
                            formik.touched.phoneNumber &&
                            Boolean(formik.errors.phoneNumber)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12} container alignItems={"center"}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={!emailValid}
                          type="number"
                          label="eth amount"
                          fullWidth
                          style={{ margin: 8 }}
                          inputProps={{ step: 0.00001 }}
                          name="ethAmount"
                          value={formik.values.ethAmount}
                          onChange={formik.handleChange}
                          placeholder="Eth Amount"
                          error={
                            formik.touched.ethAmount &&
                            Boolean(formik.errors.ethAmount)
                          }
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={!emailValid}
                          type="number"
                          label="fiat amount"
                          fullWidth
                          inputProps={{ step: 0.00001 }}
                          style={{ margin: 8 }}
                          name="fiatAmount"
                          value={formik.values.fiatAmount}
                          onChange={formik.handleChange}
                          placeholder="Fiat Amount"
                          error={
                            formik.touched.fiatAmount &&
                            Boolean(formik.errors.fiatAmount)
                          }
                        />
                        <FormControl
                          variant="outlined"
                          sx={{ width: "100%", margin: 1 }}
                        >
                          <InputLabel shrink={true} id="entity-select">
                            Entity
                          </InputLabel>
                          {entitiesOptions ? (
                            <Select
                              notched
                              error={Boolean(formik.errors.entity_uuid)}
                              variant="outlined"
                              labelId="entity-select"
                              label="Entity"
                              name="entity_uuid"
                              disabled={entitiesLoading || !emailValid}
                              onChange={formik.handleChange}
                              fullWidth
                              value={formik.values.entity_uuid}
                            >
                              {entitiesOptions?.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        disabled={!emailValid}
                        sx={{
                          margin: 1,
                        }}
                        loading={vaultIsLoading}
                        loadingText={"Vaults are loading..."}
                        freeSolo
                        renderOption={(props, option, renderState) => {
                          return (
                            <Box
                              component="li"
                              {...props}
                              key={`${option.value}${option.name}${renderState.index}`}
                            >
                              {option.name} ({option.id})
                            </Box>
                          );
                        }}
                        onChange={(_, value) => {
                          formik.setFieldValue("fireblocksId", value);
                          formik.setFieldTouched("fireblocksId");
                        }}
                        value={formik.values.fireblocksId || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            InputLabelProps={{ shrink: true }}
                            label="Fireblocks ID"
                            name="fireblocksId"
                            value={formik.values.fireblocksId || ""}
                            error={
                              formik.touched.fireblocksId &&
                              Boolean(formik.errors.fireblocksId)
                            }
                          />
                        )}
                        filterOptions={filterOptions}
                        options={fireblocksOptions || []}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <Box sx={{ margin: 1, display: "flex" }}>
                          <FormControlLabel
                            label="Send App Invite?"
                            control={
                              <Checkbox
                                disabled={!emailValid || !shouldCreateAuthUser}
                                checked={shouldCreateAuthUser}
                                onChange={() =>
                                  setShouldCreateAuthUser(!shouldCreateAuthUser)
                                }
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ margin: 1, display: "flex" }}>
                          <FormControlLabel
                            label="Is Admin?"
                            control={
                              <Checkbox
                                disabled={!emailValid}
                                name="isAdmin"
                                checked={formik.values.isAdmin}
                                onChange={formik.handleChange}
                              />
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container sx={{ alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ m: 1 }}
                          disabled={
                            !formik.dirty ||
                            formik.isSubmitting ||
                            createUserProfileMutation.isLoading ||
                            vaultIsLoading ||
                            !emailValid
                          }
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        <Button
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={refetchFormData}
                        >
                          Refetch Form Data
                        </Button>
                        <Button
                          onClick={() =>
                            formik.resetForm({
                              values: {
                                name: "",
                                fireblocksId: "",
                                entity_uuid: "",
                                phoneNumber: "",
                                fiatAmount: "",
                                email: "",
                                ethAmount: "",
                                isAdmin: false,
                              },
                            })
                          }
                          disabled={
                            formik.isSubmitting ||
                            createUserProfileMutation.isLoading ||
                            vaultIsLoading ||
                            !emailValid
                          }
                          sx={{ m: 1 }}
                          variant="contained"
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreateClient;
