import { supabase } from "supabaseClient";
import { useMutation } from "@tanstack/react-query";

export interface SaveTradeConfirmProps {
  tradeUuid: string;
}

export const emailTradeConfirmPDF = async (props: SaveTradeConfirmProps) => {
  const { data, error } = await supabase.functions.invoke(
    "email-trade-confirm",
    {
      body: JSON.stringify(props),
    }
  );
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useEmailTradeConfirmPDF = () => {
  const key = ["emailTradeConfirmPDF"];
  return useMutation(key, emailTradeConfirmPDF);
};

export default useEmailTradeConfirmPDF;
