import { supabase } from "supabaseClient";
import { useMutation } from "@tanstack/react-query";
import { Database } from "supaBaseTypes";

export interface createAdminUserProps {
  clientData: Partial<Database["public"]["Tables"]["profile"]["Row"]>;
  password: string;
}

// this needs better error handling
export const createAdminUser = async ({
  clientData,
  password,
}: createAdminUserProps) => {
  if (!clientData.email) {
    throw new Error("missing user email");
  }
  const { data: authData, error: authError } =
    await supabase.auth.api.createUser({
      email: clientData.email,
      email_confirm: true,
      password: password,
    });
  if (authError) {
    throw new Error(authError.message);
  }
  if (!authData) {
    throw new Error("missing user authentication id");
  }

  return authData;
};

const useCreateAdminUser = () => {
  const key = ["createAdminUser"];
  return useMutation(key, createAdminUser);
};

export default useCreateAdminUser;
