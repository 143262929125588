import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Page404() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        minHeight: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      direction="column"
      spacing={3}
    >
      <Grid item xs={12}>
        <Typography sx={{ color: "white" }} variant="h3">
          404 Error
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ color: "white" }}>
          Sorry, this page you visited does not exist.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => navigate("/signin")}
          sx={{ color: "white" }}
        >
          Return Home
        </Button>
      </Grid>
    </Grid>
  );
}
