import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export const getFilePath = async (
  path: string,
  bucket: string,
  time: number
) => {
  const { signedURL, error } = await supabase.storage
    .from(bucket)
    .createSignedUrl(path, time);
  if (error) {
    throw new Error(error.message);
  }
  return signedURL;
};

const useGetFilePath = (path: string, bucket: string, time: number) => {
  const key = ["getFilePath", path];
  return useQuery(key, () => getFilePath(path, bucket, time));
};

export default useGetFilePath;
