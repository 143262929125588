import { supabase } from "supabaseClient";
import { useMutation } from "@tanstack/react-query";
import { Database } from "supaBaseTypes";

export interface updateUserByIdProps {
  clientData: Partial<Database["public"]["Tables"]["profile"]["Row"]>;
  userId?: string;
}

// this needs better error handling
export const createUserProfile = async ({
  clientData,
  userId,
}: updateUserByIdProps) => {
  if (!clientData.email) {
    throw new Error("missing user email");
  }
  // check if user exists create if not
  const formattedProfileData = {
    ...clientData,
    user_id: userId,
  };
  // create or
  const { data, error } = await supabase
    .from("profile")
    .upsert(formattedProfileData);

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useCreateUserProfile = () => {
  const key = ["createUserProfile"];
  return useMutation(key, createUserProfile);
};

export default useCreateUserProfile;
