import React from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { IconButton } from "@mui/material";
import { getFilePath } from "client/hooks/supabase/useGetFilePath";
// import ReplayIcon from "@mui/icons-material/Replay";
import ErrorIcon from "@mui/icons-material/Error";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { emailTradeConfirmPDF } from "client/hooks/supabase/useEmailTradeConfirmPDF";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-premium";

const calculateGrossProfit = (params: any) => {
  return (
    (Number(params.row.quote_internal) - Number(params.row.quote_external)) *
    Number(params.row.base_asset_amount)
  );
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const TradeTableColumns: GridColDef[] = [
  {
    headerName: "Client",
    field: "user_uuid",
    valueGetter: (params: GridValueGetterParams) => {
      return params.row?.user?.name || params.row?.profile?.name || "";
    },
    width: 120,
  },
  {
    headerName: "Gross Profit",
    field: "gross",
    type: "number",
    width: 120,
    valueGetter: calculateGrossProfit,
    valueFormatter: ({ value }) => {
      if (!value) {
        return value;
      }
      return currencyFormatter.format(value);
    },
  },
  {
    headerName: "Type",
    field: "trade_type",
    width: 60,
  },
  {
    headerName: "Base Asset",
    field: "baseAsset",
    valueGetter: (params: GridValueGetterParams) => {
      return params?.value?.ticker || "";
    },
    width: 120,
  },
  {
    headerName: "Base Amount",
    field: "base_asset_amount",
    type: "number",
    width: 120,
  },
  {
    headerName: "Quote Asset",
    field: "qouteAsset",
    valueGetter: (params: GridValueGetterParams) => {
      return params?.value?.ticker || "";
    },
    width: 120,
  },
  {
    headerName: "Quote Amount",
    field: "quote_asset_amount",
    type: "number",
    width: 120,
  },
  {
    headerName: "External Quote",
    field: "quote_external",
    type: "number",
    width: 120,
  },

  {
    headerName: "Internal Quote",
    field: "quote_internal",
    type: "number",
    width: 120,
  },
  {
    headerName: "Entity",
    field: "entity",
    valueGetter: (params: GridValueGetterParams) => {
      return params?.value?.name || "";
    },
    width: 80,
  },
  {
    headerName: "Country",
    field: "entity_country",
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.entity?.country_shortcode || "";
    },
    width: 70,
  },
  {
    headerName: "Trade Date",
    field: "trade_datetime",
    width: 120,
    type: "dateTime",
    valueGetter: ({ value }) => value && new Date(value).toLocaleDateString(),
  },

  {
    headerName: "Settlement",
    field: "settlement",
    width: 120,
    valueGetter: (params: GridValueGetterParams) => {
      return params?.row?.settlementCounterparty?.display_name || "";
    },
  },
  {
    headerName: "Fintrac",
    field: "has_fintrac",
    width: 40,
  },
  {
    field: "generatePdf",
    headerName: "PDF",
    width: 80,
    sortable: false,
    aggregable: false,
    renderCell: (params) => {
      const currentRow = params.row;

      const onClick = () => {
        getFilePath(currentRow.trade_confirm_path, "trade-confirms", 6000)
          .then((resp) => {
            resp ? window?.open(resp)?.focus() : null;
          })
          .catch((err) => {
            // this should switch the icon but need to do some table state management
            console.log(err);
          });
      };
      return params.rowNode.isAutoGenerated ? undefined : (
        <IconButton disabled={!currentRow.trade_confirm_path} onClick={onClick}>
          {currentRow.trade_confirm_path ? <PictureAsPdfIcon /> : <ErrorIcon />}
        </IconButton>
      );
    },
  },
  {
    field: "emailPdf",
    headerName: "Email Confirm",
    width: 120,
    sortable: false,
    aggregable: false,
    renderCell: (params) => {
      const currentRow = params.row;
      const onClick = () => {
        emailTradeConfirmPDF({ tradeUuid: currentRow.trade_uuid })
          .then((resp) => {
            // this should switch the icon but need to do some table state management
            console.log(resp);
          })
          .catch((err) => {
            // this should switch the icon but need to do some table state management
            console.log(err);
          });
      };
      return params.rowNode.isAutoGenerated ? undefined : (
        <IconButton disabled={!currentRow.trade_confirm_path} onClick={onClick}>
          {currentRow.trade_confirm_path ? (
            <ForwardToInboxIcon />
          ) : (
            <ErrorIcon />
          )}
        </IconButton>
      );
    },
  },
];

export const BVIFilterModel = [
  { columnField: "entity_country", operatorValue: "contains", value: "BVI" },
];

export const KYFilterModel = [
  { columnField: "entity_country", operatorValue: "contains", value: "KY" },
];

export const CAFilterModel = [
  { columnField: "entity_country", operatorValue: "contains", value: "CA" },
];
