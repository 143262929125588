import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Button,
  Autocomplete,
  createFilterOptions,
  Alert,
} from "@mui/material";
import { EditClientSchema } from "./EditClientUtils";
import useSelectAllEntities from "client/hooks/supabase/useSelectAllEntities";
import useUpdateUserByIdMutation from "client/hooks/supabase/useUpdateUserProfile";
import useGetAllVaultAccounts from "client/hooks/RykiApi/useGetAllVaultUsers";

interface EditClientFormProps {
  isViewOnly: boolean;
  setViewOnly: (value: boolean) => void;
  userProfile: any;
  userVaultData: any;
  refetchUserVaultData: any;
}
const EditClientForm = ({
  isViewOnly,
  setViewOnly,
  userProfile,
  userVaultData,
  refetchUserVaultData,
}: EditClientFormProps) => {
  const {
    data: entitiesData,
    isLoading: entitiesLoading,
    refetch: refetchEntitiesData,
  } = useSelectAllEntities();
  const entitiesOptions = entitiesData?.map((party: any) => {
    return {
      ...party,
      label: `${party.name} (${party.country_shortcode})`,
      value: party.uuid,
    };
  });

  // this needs an error reload
  const {
    data: vaultData,
    isLoading: vaultIsLoading,
    refetch: refetchVaultData,
  } = useGetAllVaultAccounts();
  const updateUserByIdMutation = useUpdateUserByIdMutation();
  const fireblocksOptions = vaultData?.map((party: any) => {
    return {
      ...party,
      label: party.name,
      value: party.id,
    };
  });
  // @ts-ignore
  const filterOptions = createFilterOptions<any>({
    stringify: (option) => `${option.value}${option.name}`,
  });
  const [successAlert, setSuccessAlert] = useState("");

  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(false);
    const formattedData = {
      clientData: {
        name: values.name,
        email: values.email,
        role: "client",
        fireBlockID: values.fireblocksId.id,
        phone_number: values.phoneNumber,
        fiat_amount: values.fiatAmount,
        eth_amount: values.ethAmount,
        entity_id: values.entity_uuid,
      },
      value: userProfile.user_id ? userProfile.user_id : userProfile.id,
      param: userProfile.user_id ? "user_id" : "id",
    };
    updateUserByIdMutation.mutate(formattedData, {
      onSuccess: () => (
        setSuccessAlert("Client updated."), setViewOnly(!isViewOnly)
      ),
      onError: (resp) => console.log(resp),
    });
  };
  const refetchFormData = () => {
    refetchVaultData();
    refetchEntitiesData();
    refetchUserVaultData();
  };

  // needs a timeout to remove the alert

  // this should be the get user vault
  const userVault =
    {
      ...userVaultData,
      label: userVaultData?.name,
      value: userVaultData?.id,
    } || undefined;

  return (
    <Formik
      validateOnBlur={false}
      validationSchema={EditClientSchema}
      onSubmit={handleSubmit}
      initialValues={{
        name: userProfile?.name || "",
        email: userProfile?.email || "",
        fireblocksId: userVault || "",
        phoneNumber: userProfile?.phone_number || "",
        fiatAmount: userProfile?.fiat_amount || "",
        ethAmount: userProfile?.eth_amount || "",
        entity_uuid: userProfile?.entity_id || "",
      }}
      enableReinitialize={true}
      validateOnChange={false}
    >
      {(formik) => {
        return (
          <Form>
            <Grid container sx={{ backgroundColor: "white" }}>
              {/* needs a generic alert component */}
              {successAlert && !isViewOnly ? (
                <Alert variant="filled" severity="success">
                  {successAlert}
                </Alert>
              ) : null}
              <Grid
                item
                xs={12}
                container
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {/* need to figure out how to format this, maybe move it lower */}
                <Button
                  variant="contained"
                  sx={{ m: 1, minWidth: 100 }}
                  // needs to reset form too
                  //  formik.resetForm()
                  onClick={() => setViewOnly(!isViewOnly)}
                >
                  {isViewOnly ? "Edit" : "Cancel"}
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} container>
                <Grid item xs={12} container alignItems={"center"}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={isViewOnly}
                    label="Name"
                    style={{ width: "100%", margin: 8 }}
                    name="name"
                    onChange={formik.handleChange}
                    placeholder="name"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    value={formik.values.name}
                  />

                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={isViewOnly}
                    label="Email"
                    style={{ width: "100%", margin: 8 }}
                    name="email"
                    onChange={formik.handleChange}
                    placeholder="email"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    value={formik.values.email}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={isViewOnly}
                    type="tel"
                    label="Phone Number"
                    style={{ width: "100%", margin: 8 }}
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    placeholder="Phone Number"
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={12} container alignItems={"center"}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={isViewOnly}
                    type="number"
                    label="eth amount"
                    fullWidth
                    style={{ margin: 8 }}
                    inputProps={{ step: 0.00001 }}
                    name="ethAmount"
                    onChange={formik.handleChange}
                    placeholder="Eth Amount"
                    value={formik.values.ethAmount}
                    error={
                      formik.touched.ethAmount &&
                      Boolean(formik.errors.ethAmount)
                    }
                  />
                  <TextField
                    disabled={isViewOnly}
                    type="number"
                    label="fiat amount"
                    fullWidth
                    inputProps={{ step: 0.00001 }}
                    style={{ margin: 8 }}
                    name="fiatAmount"
                    onChange={formik.handleChange}
                    value={formik.values.fiatAmount}
                    placeholder="Fiat Amount"
                    error={
                      formik.touched.fiatAmount &&
                      Boolean(formik.errors.fiatAmount)
                    }
                  />
                  <FormControl
                    variant="outlined"
                    sx={{ width: "100%", margin: 1 }}
                  >
                    <InputLabel shrink={true} id="entity-select">
                      Entity
                    </InputLabel>
                    {entitiesOptions ? (
                      <Select
                        notched
                        error={Boolean(formik.errors.entity_uuid)}
                        variant="outlined"
                        labelId="entity-select"
                        label="Entity"
                        name="entity_uuid"
                        value={formik.values.entity_uuid}
                        disabled={entitiesLoading || isViewOnly}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        {entitiesOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={isViewOnly}
                  sx={{
                    margin: 1,
                  }}
                  loading={vaultIsLoading}
                  loadingText={"Vaults are loading..."}
                  freeSolo
                  renderOption={(props, option, renderState) => {
                    return (
                      <Box
                        component="li"
                        {...props}
                        key={`${option.value}${option.name}${renderState.index}`}
                      >
                        {option.name} ({option.id})
                      </Box>
                    );
                  }}
                  onChange={(_, value) => {
                    formik.setFieldValue("fireblocksId", value);
                  }}
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  value={formik.values.fireblocksId || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      label="Fireblocks ID"
                      name="fireblocksId"
                      value={formik.values.fireblocksId || null}
                      error={
                        formik.touched.fireblocksId &&
                        Boolean(formik.errors.fireblocksId)
                      }
                    />
                  )}
                  filterOptions={filterOptions}
                  options={fireblocksOptions || []}
                />
              </Grid>
              <Grid item xs={12} container sx={{ alignItems: "center" }}>
                {isViewOnly ? null : (
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ m: 1 }}
                      disabled={formik.isSubmitting || vaultIsLoading}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
                {isViewOnly ? null : (
                  <Grid
                    item
                    xs={6}
                    sx={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <Button
                      sx={{ m: 1 }}
                      variant="contained"
                      onClick={refetchFormData}
                    >
                      Refetch
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditClientForm;
