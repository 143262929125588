import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { supabase } from "../../../supabaseClient";

export default function ProtectedRoute({ children }: any) {
  const user = supabase.auth.user();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
