import { Form, Input, Button, Space, message, Row, Col, Spin } from "antd";
import "../../../App.css";
import "./EditClientDetails.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { ReactComponent as BackImage } from "../../../assets/back-button.svg";
import { Grid } from "@mui/material";
import { FireBlocksUserType } from "apiTypes";
import useGetAllVaultAccounts from "client/hooks/RykiApi/useGetAllVaultUsers";
import useUpdateUserByIdMutation from "client/hooks/supabase/useUpdateUserProfile";
import useSelectUserByParam from "client/hooks/supabase/useSelectUserProfileByParam";

interface handleOnChangeProps {
  key:
    | "currency"
    | "email"
    | "eth_amount"
    | "fiat_amount"
    | "fireBlockID"
    | "name"
    | "id"
    | "phone_number";
  value: string;
}

const defaultClientData = {
  currency: "",
  email: "",
  eth_amount: "",
  fiat_amount: "",
  fireBlockID: "",
  name: "",
  id: 0,
  phone_number: "",
};
const EditClientDetails = () => {
  const { type } = useParams();
  const [value, setValue] = useState("");
  const [custodianUsers, setCustodianUsers] = useState<
    FireBlocksUserType[] | []
  >([]);

  const {
    data: userProfile,
    isLoading: userProfileLoading,
    isError: userProfileError,
  } = useSelectUserByParam("user_id", type || "");

  useEffect(() => {
    setClientData((prevState: any) => {
      return { ...prevState, ...userProfile?.[0] };
    });
  }, [userProfile]);

  const [clientData, setClientData] = useState<any>(defaultClientData);

  const {
    data: vaultData,
    isLoading: vaultIsLoading,
    isError: vaultIsError,
  } = useGetAllVaultAccounts();

  const navigate = useNavigate();

  useEffect(() => {
    setCustodianUsers(vaultData);
  }, [vaultData]);
  const updateUserByIdMutation = useUpdateUserByIdMutation();
  const onSubmit = () => {
    updateUserByIdMutation.mutate(
      {
        clientData,
        value: type || "",
        param: "user_id",
      },
      {
        onSuccess: () => {
          message.success("Update successfully");
        },
        onError: () => {
          message.error("Update Error, please try again.");
        },
      }
    );
  };

  const handleOnChange = ({ key, value }: handleOnChangeProps) => {
    setClientData((prevState: any) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  // user proper  error handling and loaidn gspinner
  if (
    userProfileLoading ||
    vaultIsLoading ||
    vaultIsError ||
    userProfileError
  ) {
    return (
      <div
        style={{
          margin: "200px 0px 48px 0px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        sx={{
          margin: 2,
        }}
      >
        <Space className="back-navidation">
          <BackImage
            style={{ width: "18px", height: "24px", cursor: "pointer" }}
            onClick={() => navigate("/client/" + type)}
            alt=""
          />
        </Space>
        <div
          style={{
            maxWidth: "833px",
            margin: "auto",
            padding: "auto",
          }}
        >
          <div className="main-heading" style={{ marginBottom: "40px" }}>
            Edit Client profile
          </div>
          <Form
            layout="vertical"
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={() => onSubmit()}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  className="form-text"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Enter email"
                    type="email"
                    value={clientData.email || ""}
                    onChange={(e) =>
                      handleOnChange({ key: "email", value: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  className="form-text"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Enter name"
                    type="text"
                    value={clientData.name || ""}
                    onChange={(e) =>
                      handleOnChange({ key: "name", value: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  className="form-text"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Enter phone number"
                    type="text"
                    value={clientData.phone_number || ""}
                    onChange={(e) =>
                      handleOnChange({
                        key: "phone_number",
                        value: e.target.value,
                      })
                    }

                    // value={phoneNumber}
                    // onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={2}>
                <Form.Item
                  className="form-text"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Add Eth amount $ (if applicable)"
                    type="number"
                    // value={eth}
                    value={clientData.eth_amount || ""}
                    onChange={(e) =>
                      handleOnChange({
                        key: "eth_amount",
                        value: e.target.value,
                      })
                    }

                    // onChange={(e) => setEth(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <Form.Item
                  className="form-text"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Add Fiat amount $ (if applicable)"
                    type="number"
                    value={clientData.fiat_amount || ""}
                    onChange={(e) =>
                      handleOnChange({
                        key: "fiat_amount",
                        value: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px", borderRadius: "2px" }}>
              <Col
                span={11}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#17314c",
                  borderRadius: "10px",
                  height: "50px",
                  padding: "0px 15px 0px 15px",
                  alignItems: "center",
                }}
              >
                <Space>
                  <span className="fireblock-heading"> Fireblocks ID : </span>
                  <span className="fireblock-text">
                    {clientData.fireBlockID}
                  </span>
                </Space>
                <CloseOutlined
                  style={{ fontSize: "20px", color: "white" }}
                  onClick={() =>
                    handleOnChange({ key: "fireBlockID", value: "" })
                  }
                />
              </Col>
              <Col span={11}></Col>
            </Row>

            <Input
              prefix={<SearchOutlined />}
              bordered={false}
              className="primary-input"
              style={{
                backgroundColor: "#dee1e4",
                height: "50px",
              }}
              placeholder="Fireblocks ID lookup"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            {!clientData.fireBlockID ? (
              <>
                {custodianUsers
                  .filter(
                    (m: any) =>
                      m?.name.toLowerCase().includes(value.toLowerCase()) ||
                      m?.id.includes(value)
                  )
                  .map((m: any, i: any) => {
                    return (
                      <div
                        key={i}
                        style={{
                          background: "rgba(0, 0, 0, 0.36)",
                          borderRadius: "10px",
                          margin: "40px 0px 0px 0px",
                          padding: "50px 50px 50px 50px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span>
                            <div className="fireblock-heading">Name</div>
                            <div className="fireblock-text">{m.name}</div>
                          </span>
                          <span>
                            <div className="fireblock-heading">
                              Fireblocks ID
                            </div>
                            <div className="fireblock-text">{m.id} </div>
                          </span>
                          <Button
                            style={{ width: "130px", height: "50px" }}
                            type="primary"
                            className="primary-button"
                            onClick={() =>
                              handleOnChange({
                                key: "fireBlockID",
                                value: m.id,
                              })
                            }
                          >
                            Select
                          </Button>
                        </div>

                        <Space
                          direction="vertical"
                          size="large"
                          style={{ width: "100%", marginTop: "20px" }}
                        >
                          {m.assets.map((a: any) => {
                            return (
                              <div
                                key={a.id}
                                style={{
                                  background: "#17314c",
                                  borderRadius: "5px",
                                  padding: "12px 32px 12px 32px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    maxWidth: "800px",
                                  }}
                                >
                                  <div style={{ width: "50%" }}>
                                    <span className="fireblock-asset">
                                      Asset :{" "}
                                    </span>
                                    <span className="fireblock-asset">
                                      {a.id}{" "}
                                    </span>
                                  </div>
                                  <div style={{ width: "50%" }}>
                                    <span className="fireblock-asset">
                                      Balance :{" "}
                                    </span>
                                    <span className="fireblock-asset">
                                      {a.balance}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Space>
                      </div>
                    );
                  })}
              </>
            ) : null}

            <Form.Item style={{ paddingTop: "60px", margin: "0px" }}>
              <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{ height: "50px" }}
                  type="primary"
                  htmlType="submit"
                  className="primary-button"
                >
                  Save changes
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Grid>
    </Grid>
  );
};

export default EditClientDetails;
