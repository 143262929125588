import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { supabase } from "supabaseClient";
import { ReactComponent as Logo } from "../../../assets/image 2.svg";
import { theme } from "../../../theme/CustomTheme";

const AppHeader = () => {
  const navigate = useNavigate();
  const user = supabase.auth.user();
  if (!user) {
    return null;
  }
  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.colors.primary }}>
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Logo
          style={{
            width: "130px",
            height: "43px",
          }}
          alt=""
        />
        <Box>
          <Button sx={{ color: "#fff" }} onClick={() => navigate("/clients")}>
            Clients
          </Button>
          <Button
            sx={{ color: "#fff" }}
            onClick={() => navigate("/investments")}
          >
            Investments
          </Button>
          <Button
            sx={{ color: "#fff" }}
            onClick={() => navigate("/tradeblotter")}
          >
            Blotter
          </Button>
          <Button
            sx={{ color: "#fff" }}
            onClick={() => navigate("/trade-history")}
          >
            Trade List
          </Button>
          <Button sx={{ color: "#fff" }} onClick={() => navigate("/settings")}>
            Settings
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
