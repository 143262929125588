import React, { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Col, Row, Space, Spin, Table } from "antd";
import axios from "axios";
import { Grid } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../../App.css";
import { ReactComponent as BackImage } from "../../../assets/back-button.svg";
import { supabase } from "../../../supabaseClient";

// Type this last with the proper api calls

function ClientCurrency() {
  const { type, type1 } = useParams();
  const [client, setClient] = useState<any>([]);
  const [fireblock, setFireblock] = useState<any>();
  const [currency, setCurrency] = useState<any>([]);
  const [investment, setInvestment] = useState<any>([]);
  const [clientpool, setClientpool] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    supabase
      .from("profile")
      .select("*")
      .eq("id", type)
      .then(({ data }) => {
        setClient(data?.[0]);
      });

    supabase
      .from("clientpool")
      .select("investment_id")
      .eq("user_id", type)
      .then(({ data }) => {
        const arr = [] as any;
        data?.forEach((f) => {
          arr?.push(f?.investment_id);
        });
        setClientpool(data);
        supabase
          .from("investmentDetails")
          .select("*")
          .eq("currency", type1)
          .in("id", arr)
          .then(({ data }) => {
            setInvestment(data);
          });
      });
  }, [clientpool, fireblock, type, type1]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_CLOUD}`).then((res) => {
      setFireblock(
        res.data.filter((f: any) => f.id === client?.fireBlockID)[0].assets
      );
      setCurrency(
        res.data
          .filter((f: any) => f.id === client?.fireBlockID)[0]
          .assets.filter((f: any) => f.id === type1)
      );
    });
  }, [client, type1]);

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "1",
    //   width: 200,
    //   render: (record) => <>{record.id}</>,
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "2",
    },
    {
      title: "Provider",
      dataIndex: "name",
      key: "3",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "4",
    },
    {
      title: "Structure",
      dataIndex: "structure",
      key: "5",
    },
    {
      title: "Effective date",
      dataIndex: "effectiveDate",
      key: "6",
    },
    {
      title: "Expiry date",
      dataIndex: "expiryDate",
      key: "7",
    },
    {
      title: "Return date",
      dataIndex: "returnDate",
      key: "8",
    },
    {
      title: "",
      dataIndex: "viewmore",
      key: "10",

      render: (record: any) => (
        <Link
          style={{ color: "white" }}
          to={"/investmentclientpool/" + record.id}
        >
          <RightOutlined />
        </Link>
      ),
    },
  ];

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        sx={{
          margin: 2,
        }}
      >
        <Space className="back-navidation">
          <BackImage
            style={{ width: "18px", height: "24px", cursor: "pointer" }}
            onClick={() => navigate(`/client/${client.user_id}`)}
            alt=""
          />
        </Space>

        <div>
          <Space
            direction="vertical"
            size="large"
            style={{
              width: "100%",

              background: "rgba(0, 0, 0, 0.36)",
              borderRadius: "10px",
              padding: "40px 80px 40px 80px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="client-heading">Name</div>
                <div className="client-normal">{client.name}</div>
              </div>

              <div>
                <div className="client-heading">Email</div>
                <div className="client-normal">{client.email}</div>
              </div>
              <div>
                <div className="client-heading">Ph. Number</div>
                <div className="client-normal">{client.phone_number}</div>
              </div>
              <div>
                <div className="client-heading">Fireblocks ID</div>
                <div className="client-normal">{client.fireBlockID}</div>
              </div>
            </div>
          </Space>
          <Space
            direction="vertical"
            size="large"
            style={{
              width: "100%",
              marginTop: "25px",
              background: "rgba(0, 0, 0, 0.36)",
              borderRadius: "10px",
              padding: "40px 80px 40px 80px",
            }}
          >
            <div className="main-heading">{type1}</div>
            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <div>
                  <span className="client-heading">Total Quantity: </span>
                  {Number(currency.length) !== 0 ? (
                    <span className="client-normal">{currency[0].total}</span>
                  ) : (
                    <span className="client-normal">0</span>
                  )}
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <div>
                  <span className="client-heading">Unallocated : </span>
                  <span className="client-normal">--</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <div>
                  <span className="client-heading">Staked Amount: </span>
                  {Number(currency.length) !== 0 ? (
                    <span className="client-normal">0</span>
                  ) : (
                    <span className="client-normal">0</span>
                  )}
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <div>
                  <span className="client-heading">
                    Total Interest Accured :{" "}
                  </span>
                  <span className="client-normal">--</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <div>
                  <span className="client-heading">Balance : </span>
                  {Number(currency.length) !== 0 ? (
                    <span className="client-normal">{currency[0].balance}</span>
                  ) : (
                    <span className="client-normal">0</span>
                  )}
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <div>
                  <span className="client-heading">Available : </span>
                  {Number(currency.length) !== 0 ? (
                    <span className="client-normal">
                      {currency[0].available}
                    </span>
                  ) : (
                    <span className="client-normal">0</span>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <div>
                  <span className="client-heading">Locked Amount: </span>
                  {Number(currency.length) !== 0 ? (
                    <span className="client-normal">
                      {currency[0].lockedAmount}
                    </span>
                  ) : (
                    <span className="client-normal">0</span>
                  )}
                </div>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <div>
                  <span className="client-heading">Hold : </span>
                  {Number(currency.length) !== 0 ? (
                    <span className="client-normal">{currency[0].frozen}</span>
                  ) : (
                    <span className="client-normal">0</span>
                  )}
                </div>
              </Col>
            </Row>
          </Space>
        </div>

        <div style={{ marginTop: "55px" }}>
          <Table
            locale={{ emptyText: <Spin /> }}
            style={{ borderRadius: "0px" }}
            pagination={false}
            columns={columns}
            dataSource={investment}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default ClientCurrency;
