import React, { useState } from "react";
import { Grid, Typography, Button, TextField, Alert } from "@mui/material";
import { supabase } from "../../../supabaseClient";
import { useNavigate } from "react-router-dom";
import useLogOut from "client/hooks/supabase/useLogout";
import { theme } from "../../../theme/CustomTheme";

const Settings = () => {
  const handleLogOutMutation = useLogOut();
  const [passwordSubmitError, setPasswordSubmitError] = useState("");
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  // this should confirm existing password too
  const onSubmit = async () => {
    const { error } = await supabase.auth.update({
      password: newPassword,
    });
    if (error) {
      setPasswordSubmitError(error.message);
      console.log(error.message);
    } else {
      setSuccessfulSubmit(true);
      // this needs a success alert
      console.log("success");
    }
  };

  const logout = () => {
    handleLogOutMutation.mutate(undefined, {
      onSuccess: () => navigate("/signin"),
      // type this properly
      onError: (error) => console.log(error),
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} container sx={{ m: 2, alignItems: "center" }}>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: "white" }}>
            Settings
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        sx={{ m: 2, backgroundColor: "white" }}
      >
        {successfulSubmit ? (
          <Alert variant="outlined" severity="success">
            Success
          </Alert>
        ) : passwordSubmitError ? (
          <Alert variant="outlined" severity="error">
            {passwordSubmitError}
          </Alert>
        ) : null}
        <Grid item xs={12} container sx={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: theme.colors.primary,
              }}
              variant="h6"
            >
              Change Password
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="New Password"
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          >
            New Password
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirm Password"
            type="password"
            error={newPassword !== confirmPassword}
            helperText={
              newPassword !== confirmPassword
                ? "Passwords do not match"
                : undefined
            }
            onChange={(e) => setConfirmPassword(e.target.value)}
          >
            Confirm Password
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={newPassword !== confirmPassword}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ mt: 2, mb: 2 }}
            variant="contained"
            onClick={() => logout()}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Settings;
