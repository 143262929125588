import React, { useEffect, useState } from "react";
import LoadingSpinner from "client/components/LoadingSpinner/LoadingSpinner";
import { Grid, Button, Box, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";

import { useNavigate } from "react-router-dom";
import useSelectAllUsers from "client/hooks/supabase/useSelectAllUsers";
import { ClientTableColumns } from "./ClientUtils";

const Client = () => {
  const [data, setData] = useState<any>(null);

  const navigate = useNavigate();
  // this needs error handling
  const {
    data: allUsers,
    isLoading: allUsersLoading,
    refetch: refetchAllUsers,
  } = useSelectAllUsers();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button onClick={() => navigate("/createclient")}>+ Create New</Button>
        <Button disabled={allUsersLoading} onClick={() => refetchAllUsers()}>
          Reload
        </Button>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    if (allUsers) {
      setData(allUsers);
    }
  }, [allUsers]);

  const initialColumnVisible = {
    has_fintrac: false,
    settlement: false,
  };
  const handleOnRowClick = (params: any) => {
    const id = params.row.user_id
      ? params.row.user_id
      : params.row.profile_uuid;
    const idType = params.row.user_id ? "user_id" : "profile_uuid";
    navigate("/client/" + id, { state: { uid: id, idType } });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box m={2}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Clients
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {allUsersLoading ? (
          <LoadingSpinner />
        ) : data ? (
          <Box m={2}>
            <DataGridPremium
              onRowClick={handleOnRowClick}
              initialState={{
                columns: {
                  columnVisibilityModel: initialColumnVisible,
                },
              }}
              components={{
                Toolbar: CustomToolbar,
              }}
              sx={{ backgroundColor: "white", height: 600 }}
              rows={data}
              columns={ClientTableColumns}
            />
          </Box>
        ) : (
          <Box m={2}>
            <Button variant="contained" onClick={() => refetchAllUsers()}>
              Refetch users
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Client;
