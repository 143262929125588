import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export const getAllAssets = async () => {
  const { data, error } = await supabase
    .from("asset_master")
    .select("*")
    .order("sort_order", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSelectAllAssets = () => {
  const key = ["getAllAssets"];
  return useQuery(key, () => getAllAssets());
};

export default useSelectAllAssets;
