import {
  Form,
  Input,
  Button,
  Space,
  message,
  Row,
  Col,
  Select,
  DatePicker,
} from "antd";
import { Grid, Typography } from "@mui/material";
import { supabase } from "../../../supabaseClient";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./createInvestment.css";
import { ReactComponent as BackImage } from "../../../assets/back-button.svg";
import moment, { Moment } from "moment";
const { Option } = Select;

function CreateInvestment() {
  const [name, setName] = useState<any>("");
  const [currency, setCurrency] = useState("");
  const [quantity, setQuantity] = useState("");
  const [structure, setStructure] = useState("");
  const [rate, setRate] = useState("");
  const [effectivedate, setEffectivedate] = useState<Moment | string | null>(
    ""
  );
  const [expirydate, setExpirydate] = useState<Moment | string | null>("");
  const [returndate, setReturndate] = useState<Moment | string | null>("");
  const navigate = useNavigate();

  //Dropdown
  const currencyHandler = (value: any) => {
    setCurrency(value);
  };
  const structureHandler = (value: any) => {
    setStructure(value);
  };
  //Dropdown

  //create investment
  const onFinish = async () => {
    const { error } = await supabase.from("investmentDetails").insert([
      {
        name: name,
        currency: currency,
        quantity: quantity,
        structure: structure,
        rate: rate,
        effectiveDate: moment(effectivedate).format("YYYY-MM-DD"),
        expiryDate: moment(expirydate).format("YYYY-MM-DD"),
        returnDate: moment(returndate).format("YYYY-MM-DD"),
      },
    ]);
    if (!error) {
      message.success("Saved successfully");
      navigate("/investments");
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <BackImage
          style={{ width: "18px", height: "24px", cursor: "pointer" }}
          onClick={() => navigate("/investments")}
          alt=""
        />
        <Typography variant="body1" sx={{ color: "white" }}>
          Create New Investment Record
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            remember: true,
          }}
        >
          <Space
            direction="vertical"
            style={{ width: "100%", padding: "40px 10px 40px 10px" }}
          >
            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <Form.Item
                  className="form-text"
                  label="Name"
                  name="name"
                  style={{
                    color: "white",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Enter name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <Form.Item
                  className="form-text"
                  name="currency"
                  label="Select currency"
                  rules={[
                    {
                      required: true,
                      message: "Please select currency!",
                    },
                  ]}
                >
                  <Select
                    className="primary-select"
                    bordered={false}
                    value={currency}
                    placeholder="Select currency"
                    onChange={currencyHandler}
                  >
                    <Option value="ETH">ETH</Option>
                    <Option value="USDT_ERC20">USDT_ERC20</Option>
                    <Option value="BTC">BTC</Option>
                    <Option value="USDC">USDC</Option>
                    <Option value="USD">USD</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <Form.Item
                  className="form-text"
                  name="quantity"
                  label="Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter quantity!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Enter quantity"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <Form.Item
                  className="form-text"
                  name="structure"
                  label="Select structure"
                  rules={[
                    {
                      required: true,
                      message: "Please select structure!",
                    },
                  ]}
                >
                  <Select
                    className="primary-select"
                    bordered={false}
                    value={structure}
                    placeholder="Select structure"
                    onChange={structureHandler}
                  >
                    <Option value="FT">FT</Option>
                    <Option value="OT">OT</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <Form.Item
                  className="form-text"
                  name="rate"
                  label="Rate"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    bordered={false}
                    className="form-input"
                    placeholder="Enter rate"
                    type="number"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <Form.Item
                  className="form-text"
                  name="effective date"
                  label="Effective Date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter effective date!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    bordered={false}
                    style={{ width: "100%" }}
                    className="form-input-datepicker"
                    placeholder="Enter effective date"
                    onChange={(e) => setEffectivedate(e)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12} style={{ paddingRight: "60px" }}>
                <Form.Item
                  className="form-text"
                  name="expiry date"
                  label="Expiry date"
                  rules={[
                    {
                      required: structure === "FT" ? true : false,
                      message: "Please enter expiry date!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    bordered={false}
                    style={{ width: "100%" }}
                    className="form-input-datepicker"
                    placeholder="Enter expiry date"
                    onChange={(e) => setExpirydate(e)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingLeft: "60px" }}>
                <Form.Item
                  className="form-text"
                  style={{ color: "#ffffff" }}
                  name="return date"
                  label="Return Date"
                  rules={[
                    {
                      required: structure === "FT" ? true : false,
                      message: "Please enter return date!",
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    bordered={false}
                    style={{ width: "100%" }}
                    className="form-input-datepicker"
                    placeholder="Enter return date"
                    onChange={setReturndate}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Space>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Form.Item>
              <Button
                className="primary-button"
                type="primary"
                htmlType="submit"
                style={{
                  width: "164px",
                  height: "40px",
                  borderRadius: "50px",
                  border: "none",
                }}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Grid>
    </Grid>
  );
}

export default CreateInvestment;
