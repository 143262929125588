import {
  Button,
  Space,
  message,
  Table,
  Empty,
  Select,
  InputNumber,
} from "antd";
import "../../../App.css";
import { Grid } from "@mui/material";
import "./investmentclientpool.css";
import { supabase } from "../../../supabaseClient";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { ReactComponent as BackImage } from "../../../assets/back-button.svg";

const { Option } = Select;

function Investmentclientpool() {
  const { type } = useParams();
  const [investment, setInvestment] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [client, setClient] = useState<any>();
  const [edit, setEdit] = useState<any>("");
  const [quantity, setQuantity] = useState<any>(0);
  const [share, setShare] = useState<any>(0);
  const [totalinterest, setTotalinterest] = useState<any>(0);
  const [dailyinterest, setDailyinterest] = useState<any>(0);
  const [fireblock, setFireblock] = useState<any>("");
  const [clientid, setClientid] = useState<any>();
  const [deletehideshow, setDeletehideshow] = useState<any>(false);
  const navigate = useNavigate();
  const [diffDays, setdiffDays] = useState<any>("");

  useEffect(() => {
    supabase
      .from("profile")
      .select("*")
      .then(({ data, error }) => {
        if (!error) setClient(data?.filter((f) => f?.status === "Active"));
      });
    supabase
      .from("investmentDetails")
      .select("*")
      .eq("investment_uuid", type)
      .then(({ data }) => {
        const effectiveDate = new Date(data?.[0]?.effectiveDate) as any;
        const returnDate = new Date(data?.[0]?.returnDate) as any;

        const diffTime = Math.abs(returnDate - effectiveDate);

        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        setdiffDays(diffDays);

        setInvestment(data?.[0]);
      });
    supabase
      .from("clientpool")
      .select("*")
      .eq("investment_id", type)
      .order("id", { ascending: false })
      .then(({ data }) => {
        setDataSource(data);
      });
  }, [type]);

  const columns = [
    {
      width: "500px",
      title: "Client Name",
      dataIndex: "clientname",
      key: "1",
      render: (record: any) => (
        <>
          {Number(edit) === Number(record?.id) && record ? (
            <Select
              allowClear
              showSearch
              placement="bottomRight"
              style={{
                width: "150px",
                height: "30px !important",
                borderRadius: "5px",
              }}
              className="fireblock-select"
              bordered={false}
              value={clientid}
              placeholder="Select Fireblock ID"
              onChange={(event) => {
                setClientid(event);
                setFireblock(
                  client?.filter((f: any) => f.id === event)[0]?.fireBlockID
                );
              }}
              filterOption={(input: any, option: any) =>
                option?.children?.toLowerCase()?.includes(input?.toLowerCase())
              }
            >
              {client?.map((c: any) => {
                return (
                  <Option key={c?.id} value={c?.id}>
                    {c?.name}
                  </Option>
                );
              })}
            </Select>
          ) : (
            record?.client_name
          )}
        </>
      ),
    },
    {
      width: "250px",
      title: "Fireblocks ID",
      dataIndex: "fireblock",
      key: "2",
      render: (record: any) => (
        <>
          {Number(edit) === Number(record?.id) ? (
            <>{fireblock}</>
          ) : (
            record?.fireblock
          )}
        </>
      ),
    },
    {
      width: "150px",
      title: "Quantity",
      dataIndex: "quantity",
      key: "3",
      render: (record: any) => (
        <>
          {Number(edit) === Number(record?.id) ? (
            <InputNumber
              bordered={false}
              value={quantity}
              style={{
                backgroundColor: "#dee1e4",
                height: "30px",
                textAlign: "center",
                borderRadius: "5px",
              }}
              onChange={(e) => {
                setQuantity(Number(e).toFixed(4));
                setTotalinterest(
                  Number(
                    ((e * investment.rate) / 100 / 365) * diffDays
                  ).toFixed(4)
                );
                setShare(Number((e / investment?.quantity) * 100).toFixed(4));
                setDailyinterest(
                  Number((e * investment?.rate) / 100 / 365).toFixed(4)
                );
              }}
            />
          ) : (
            Number(record?.quantity).toFixed(4)
          )}
        </>
      ),
    },
    {
      width: "150px",
      title: "%-Share",
      dataIndex: "share",
      key: "4",
      render: (record: any) => (
        <>
          {Number(edit) === Number(record?.id) ? (
            <InputNumber
              bordered={false}
              className="primary-input"
              value={share}
              style={{
                backgroundColor: "#dee1e4",
                height: "30px",
                textAlign: "center",
                borderRadius: "5px",
              }}
              onChange={(e) => {
                setShare(e);
                setQuantity(
                  Number((e * investment?.quantity) / 100).toFixed(4)
                );
                setTotalinterest(
                  Number(
                    ((((e * investment?.quantity) / 100) * investment?.rate) /
                      100 /
                      365) *
                      diffDays
                  ).toFixed(4)
                );
                setDailyinterest(
                  Number(
                    (((e * investment?.quantity) / 100) * investment?.rate) /
                      100 /
                      365
                  ).toFixed(4)
                );
              }}
            />
          ) : (
            Number(record?.share).toFixed(4)
          )}
        </>
      ),
    },
    {
      width: "250px",
      title: "Total Interest",
      dataIndex: "total_interest",
      key: "5",
      render: (record: any) => (
        <>
          {Number(edit) === Number(record?.id) ? (
            <InputNumber
              bordered={false}
              className="primary-input"
              value={totalinterest}
              style={{
                backgroundColor: "#dee1e4",
                height: "30px",
                textAlign: "center",
                borderRadius: "5px",
              }}
              onChange={(e) => setTotalinterest(e)}
            />
          ) : (
            Number(record?.total_interest).toFixed(4)
          )}
        </>
      ),
    },
    {
      width: "250px",
      title: "Daily Interest",
      dataIndex: "daily_interest",
      key: "6",
      render: (record: any) => (
        <>
          {Number(edit) === Number(record?.id) ? (
            <InputNumber
              bordered={false}
              className="primary-input"
              value={dailyinterest}
              style={{
                backgroundColor: "#dee1e4",
                height: "30px",
                textAlign: "center",
                borderRadius: "5px",
              }}
              type="name"
              onChange={(e) => setDailyinterest(e)}
            />
          ) : (
            Number(record?.daily_interest).toFixed(4)
          )}
        </>
      ),
    },
    {
      width: "150px",
      title: "Edit",
      dataIndex: "edit",
      key: "7",
      maxWidth: "100px",
      render: (record: any) => (
        <>
          {Number(edit) === Number(record?.id) ? (
            <Button
              className="primary-button"
              style={{ width: "100px", height: "35px", borderRadius: "25px" }}
              onClick={() => saveHandler(record?.id)}
            >
              Save
            </Button>
          ) : (
            <EditFilled onClick={() => editHandler(record)} />
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "8",
      render: (record: any) => (
        <>
          {record?.status === "active" ? (
            <div
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => activeHandler(record?.id, "inactive")}
            >
              Deactivate
            </div>
          ) : (
            <div
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => activeHandler(record?.id, "active")}
            >
              Activate
            </div>
          )}
        </>
      ),
    },

    {
      title: "Delete",
      dataIndex: "delete",
      key: "9",
      maxWidth: "100px",
      render: (record: any) => (
        <DeleteFilled onClick={() => deleteHandler(record?.id)} />
      ),
    },
  ];

  async function deleteHandler(id: any) {
    const { error } = await supabase.from("clientpool").delete().eq("id", id);
    if (!error) {
      message.success("Deleted successfully");
      supabase
        .from("clientpool")
        .select("*")
        .eq("investment_id", type)
        .order("id", { ascending: false })
        .then(({ data, error }) => {
          if (!error) setDataSource(data);
        });
    }
  }

  async function saveHandler(id: any) {
    const { error } = await supabase
      .from("clientpool")
      .update({
        client_id: client.filter((f: any) => f.id == clientid)[0].user_id,
        fireblock: fireblock,
        // eslint-disable-next-line eqeqeq
        client_name: client.filter((f: any) => f.id == clientid)[0].name,
        daily_interest: dailyinterest,
        share: share,
        quantity: quantity,
        total_interest: totalinterest,
      })
      .eq("id", id);
    if (!error) {
      supabase
        .from("clientpool")
        .select("*")
        .eq("investment_id", type)
        .order("id", { ascending: false })
        .then(({ data, error }) => {
          if (!error) setDataSource(data);
        });
      message.success("Saved successfully");
    }
    setEdit("");
  }

  function editHandler(record: any) {
    setClientid(parseInt(record?.user_id));
    setDailyinterest(record?.daily_interest);
    setTotalinterest(record?.total_interest);
    setQuantity(record?.quantity);
    setShare(record?.share);
    setFireblock(record?.fireblock);
    setEdit(record?.id);
  }

  async function activeHandler(id: any, active: any) {
    const { error } = await supabase
      .from("clientpool")
      .update({
        status: active,
      })
      .eq("id", id);
    if (!error) {
      message.success("Updated successfully");
      supabase
        .from("clientpool")
        .select("*")
        .eq("investment_id", type)
        .then(({ data, error }) => {
          if (!error) setDataSource(data);
        });
    }
  }

  async function deleteInvestment() {
    const { error } = await supabase
      .from("investmentDetails")
      .delete()
      .eq("id", type);

    if (!error) {
      supabase.from("clientpool").delete().eq("investment_id", type);
      message.success("Deleted successfully");
      navigate("/investments");
    }
    setDeletehideshow(false);
  }

  function clientpool() {
    setClientid("");
    setDailyinterest(0);
    setTotalinterest(0);
    setQuantity(0);
    setShare(0);

    supabase
      .from("clientpool")
      .insert([
        {
          investment_id: type,
          currency: investment?.currency,
        },
      ])
      .then(({ data, error }) => {
        setEdit(data?.[0].id);

        if (!error) {
          message.success("Added successfully");
          supabase
            .from("clientpool")
            .select("*")
            .eq("investment_id", type)
            .order("id", { ascending: false })
            .then(({ data }) => {
              setDataSource(data);
            });
        }
      });
  }

  return (
    <Grid container>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <Space className="back-navidation">
          <BackImage
            style={{
              width: "18px",
              height: "24px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/investments")}
            alt=""
          />
        </Space>
        <div
          style={{
            background: "rgba(0, 0, 0, 0.36)",
            borderRadius: "10px",
            padding: "40px 80px 40px 80px",
            marginTop: "40px",
          }}
        >
          <div
            className="main-heading"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <>Investment Details</>{" "}
            <EditFilled onClick={() => navigate("/editinvestment/" + type)} />
          </div>
          <Space
            size="large"
            style={{
              padding: "20px 0px 20px 0px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div className="investment-quantity">{investment?.quantity}</div>{" "}
            <Space
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="investment-currency">{investment?.currency}</div>
              <div className="investment-currency">{" - "}</div>
              <div className="investment-currency">{investment?.name}</div>
            </Space>
          </Space>
          <Space
            style={{
              padding: "10px 0px 10px 0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "76%",
            }}
          >
            <div>
              <div className="investment-heading">Rate </div>
              <div className="investment-normal">{investment.rate + " %"} </div>
            </div>
            <div>
              <div className="investment-heading">Structure </div>
              <div className="investment-normal">{investment?.structure}</div>
            </div>
          </Space>
          <Space
            style={{
              padding: "10px 0px 10px 0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "80%",
            }}
          >
            <div>
              <div className="investment-heading">Effective Date </div>
              <div className="investment-normal">
                {investment.effectiveDate}
              </div>
            </div>
            <div>
              <div className="investment-heading">Expiry Date </div>
              <div className="investment-normal">{investment?.expiryDate}</div>
            </div>
          </Space>
          <Space
            style={{
              padding: "10px 0px 10px 0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "80%",
            }}
          >
            <div>
              <div className="investment-heading">Return Date : </div>
              <div className="investment-normal">{investment?.returnDate}</div>
            </div>
          </Space>
          <Space
            direction="vertical"
            size="large"
            style={{
              padding: "10px 0px 10px 0px",
            }}
          >
            <div
              className="form-text"
              style={{ cursor: "pointer" }}
              onClick={() => setDeletehideshow(true)}
            >
              <DeleteFilled />
              <span className="delete-this-record">Delete this record</span>
            </div>
            {deletehideshow ? (
              <Space direction="vertical">
                <div className="investment-heading">
                  Deleting an investment will aslo delete the clientpool.{" "}
                </div>
                <Space size="large">
                  <Button
                    className="secondary-button"
                    style={{
                      width: "100px",
                      height: "35px",
                      borderRadius: "25px",
                      color: "black",
                      fontSize: "16px",
                    }}
                    onClick={() => setDeletehideshow(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-button"
                    style={{
                      width: "100px",
                      height: "35px",
                      borderRadius: "25px",
                    }}
                    onClick={() => deleteInvestment()}
                  >
                    Confirm
                  </Button>
                </Space>
              </Space>
            ) : (
              ""
            )}{" "}
          </Space>
        </div>
      </Space>
      <Space
        direction="vertical"
        size="large"
        style={{ width: "100%", padding: "40px 0px 0 0px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",

            alignItems: "center",
          }}
        >
          <span className="main-heading">Client pool</span>
          <Button
            className="primary-button"
            style={{ width: "164px", height: "50px", borderRadius: "25px" }}
            onClick={() => clientpool()}
          >
            + Add New
          </Button>
        </div>
        <Table
          locale={{
            emptyText: (
              <div>
                <Empty description={<>No clients added yet</>} />
              </div>
            ),
          }}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
        />
      </Space>
    </Grid>
  );
}

export default Investmentclientpool;
