import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export interface getVaultAccountProps {
  vaultId: string;
  entityId: string;
}

export const getUserVault = async (vaultId: string, entityId: string) => {
  // this can just call the query directly.
  // doesnt need express since we have a backend
  const { data } = await axios.post("/api/userVaultBalance", {
    client_id: vaultId,
    entity_id: entityId,
  });
  return data;
};

export const useGetUserVault = (
  vaultId: string | any,
  entityId: string | any
) => {
  const key = ["getUserVault", vaultId];
  return useQuery({
    queryKey: key,
    queryFn: () => getUserVault(vaultId, entityId),
    enabled: !!vaultId && !!entityId,
  });
};

export default useGetUserVault;
