import { supabase } from "supabaseClient";
import { useQuery } from "@tanstack/react-query";

export const getAllTrade = async () => {
  const { data, error } = await supabase
    .from("otc_client_trades")
    .select(
      "* ,entity:entities(*), counterparty:counterparty_uuid(*),settlementCounterparty:settlement_counterparty_uuid(*), qouteAsset:quote_asset_uuid(ticker), baseAsset:base_asset_uuid(ticker),user:user_uuid(*), profile:profile_uuid(*)"
    );
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSelectAllOTCTrades = () => {
  const key = ["getAllTrades"];
  return useQuery(key, () => getAllTrade());
};

export default useSelectAllOTCTrades;
