import { supabase } from "supabaseClient";
import { useMutation } from "@tanstack/react-query";

export interface SaveTradeConfirmProps {
  tradeUuid: string;
}

export const saveTradeConfirmPDF = async (props: SaveTradeConfirmProps) => {
  const { data, error } = await supabase.functions.invoke(
    "save-trade-confirm",
    {
      body: JSON.stringify(props),
    }
  );
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const useSaveTradeConfirmPDF = () => {
  const key = ["saveTradeConfirmPDF"];
  return useMutation(key, saveTradeConfirmPDF);
};

export default useSaveTradeConfirmPDF;
