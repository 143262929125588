import React, { useState } from "react";
import { Box, Grid, Typography, Button, Tabs, Tab } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import LoadingSpinner from "client/components/LoadingSpinner/LoadingSpinner";
import useSelectAllOTCTrades from "client/hooks/supabase/useSelectAllOTCTrades";
import {
  TradeTableColumns,
  CAFilterModel,
  BVIFilterModel,
  KYFilterModel,
} from "./ListTradesUtils";
const TradesList = () => {
  const {
    data: tradesData,
    refetch: refetchTrades,
    isLoading: tradesLoading,
  } = useSelectAllOTCTrades();

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const refetch = () => {
    refetchTrades();
  };
  const initialColumnVisible = {
    has_fintrac: false,
    settlement: false,
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button disabled={tradesLoading} onClick={refetch}>
          Refetch
        </Button>
      </GridToolbarContainer>
    );
  }

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`data-tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box m={2}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Trades List
          </Typography>
        </Box>
      </Grid>
      {tradesLoading ? (
        <LoadingSpinner />
      ) : tradesData ? (
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ pb: 0.5, borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                sx={{ backgroundColor: "white", borderRadius: 1 }}
                value={tabValue}
                onChange={handleChange}
              >
                <Tab label="Total" />
                <Tab label="Canada" />
                <Tab label="British Virgin Islands" />
                <Tab label="Cayman Islands" />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <DataGridPremium
                experimentalFeatures={{ aggregation: true }}
                initialState={{
                  aggregation: {
                    model: {
                      gross: "sum",
                    },
                  },
                  columns: {
                    columnVisibilityModel: initialColumnVisible,
                  },
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{ backgroundColor: "white", height: 600 }}
                getRowId={(row) => row.trade_uuid}
                rows={tradesData}
                columns={TradeTableColumns}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <DataGridPremium
                experimentalFeatures={{ aggregation: true }}
                initialState={{
                  aggregation: {
                    model: {
                      gross: "sum",
                    },
                  },
                  columns: {
                    columnVisibilityModel: initialColumnVisible,
                  },
                  filter: {
                    filterModel: { items: CAFilterModel },
                  },
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{ backgroundColor: "white", height: 600 }}
                getRowId={(row) => row.trade_uuid}
                rows={tradesData}
                columns={TradeTableColumns}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <DataGridPremium
                experimentalFeatures={{ aggregation: true }}
                initialState={{
                  aggregation: {
                    model: {
                      gross: "sum",
                    },
                  },
                  columns: {
                    columnVisibilityModel: initialColumnVisible,
                  },
                  filter: {
                    filterModel: { items: BVIFilterModel },
                  },
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{ backgroundColor: "white", height: 600 }}
                getRowId={(row) => row.trade_uuid}
                rows={tradesData}
                columns={TradeTableColumns}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <DataGridPremium
                experimentalFeatures={{ aggregation: true }}
                initialState={{
                  aggregation: {
                    model: {
                      gross: "sum",
                    },
                  },
                  columns: {
                    columnVisibilityModel: initialColumnVisible,
                  },
                  filter: {
                    filterModel: { items: KYFilterModel },
                  },
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                sx={{ backgroundColor: "white", height: 600 }}
                getRowId={(row) => row.trade_uuid}
                rows={tradesData}
                columns={TradeTableColumns}
              />
            </TabPanel>
          </Box>
        </Grid>
      ) : (
        <Box m={2}>
          <Button variant="contained" onClick={() => refetchTrades()}>
            Refetch Trades
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default TradesList;
