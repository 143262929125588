import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";

import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";

import { InvestmentTableColumns } from "./InvestmentPageUtil";
import { supabase } from "supabaseClient";
import { useNavigate } from "react-router-dom";

const InvestmentPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  // refactor this
  const [setDataSource] = useState<any>(data);
  // refactor this
  const [setClientpool] = useState<any>([]);

  // use investmentedtails
  // use clientpool
  useEffect(() => {
    supabase
      .from("investmentDetails")
      .select("*")
      .then(({ data }) => {
        setData(data);
        setDataSource(data);
      });
    supabase
      .from("clientpool")
      .select("*")
      .then(({ data }) => setClientpool(data));
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button onClick={() => navigate("/createinvestment")}>
          + Create New
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleOnCellClick = (params: any) => {
    navigate("/investmentclientpool/" + params.id);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box m={2}>
          <Typography variant="h6" sx={{ color: "white" }}>
            Investments
          </Typography>
        </Box>
      </Grid>

      {data ? (
        <Grid item xs={12}>
          <Box m={2}>
            <DataGridPremium
              components={{
                Toolbar: CustomToolbar,
              }}
              onRowClick={handleOnCellClick}
              sx={{ backgroundColor: "white", minHeight: 500 }}
              getRowId={(row) => row.investment_uuid || row.id}
              rows={data}
              columns={InvestmentTableColumns}
            />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default InvestmentPage;
